import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Menu, message, Modal, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import API_BASE_URL from "../../../../../env";
import addIcon from "../../../../../assets/add.png";
import moreIcon from "../../../../../assets/more_vert.png";
import closeIcon from "../../../../../assets/close.png";
import infoIcon from "../../../../../assets/info.png";
import NoteViewTooltip from "../../tabs/notes/NoteViewTooltip";
import NotesOffcanvas from "../../tabs/notes/notesOffcanvas/NotesOffcanvas";
import NoteAttachmentTooltip from "./NoteAttachmentTooltip";
import AWS from "aws-sdk";
import { ACCESS_KEY, REGION, S3_BUCKET, SECRET_ACCESS_KEY } from "../../../../../String";

const Notes = () => {
    const Token = localStorage.getItem("Token");
    const { staffId } = useParams();
    const gridRef = useRef(null);
    const gridStyle = useMemo(() => ({ height: "calc(100% - 68px)", width: "100%" }), []);
    const [rowData, setRowData] = useState([]);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [note, setNote] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [noteId, setNoteId] = useState();
    const [fileKeys, setFileKeys] = useState([]);


    useEffect(() => {
        getNotes();
    }, [Token])

    const getNotes = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: staffId
            }
        }

        try {
            const res = await axios.get(`${API_BASE_URL}/api/v1/clinicNotes/get-notes`, config);

            const updatedData = await Promise.all(
                res.data.data.map(async (item) => {
                    if (item.noteUrls) {
                        const fileKeys = extractKeysFromUrls(item.noteUrls);
                        const fileSizes = await getFileSizes(fileKeys);
                        const formattedFileSizes = fileSizes.map((file) => formatSize(file.size));
                        return { ...item, fileSizes: formattedFileSizes };
                    }
                    return item;
                })
            );
            setRowData(updatedData);
        } catch (err) {
            err.response && message.error(err.response?.data.data.errors);
        } finally {
            setLoading(false);
        }
    };

    const handleEditOpen = (id) => {
        const selectedNote = rowData?.find((item) => item.noteId === id);
        setNote(selectedNote);
        setEditShow(true);
    };

    const handleDeleteNote = (id) => {
        setOpenDeleteModal(true);
        setNoteId(id);
    }

    const deleteNote = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: staffId,
                noteId: noteId
            }
        }

        axios
            .delete(`${API_BASE_URL}/api/v1/clinicNotes/delete-notes`, config)
            .then(res => {
                message.success(res?.data.data);
                setOpenDeleteModal(false);
                getNotes();
            })
            .catch(err => {
                message.error(err.response?.data.data.errors || "Failed to delete the note");
                setOpenDeleteModal(false);
            })
    }

    const formatSize = (size) => {
        if (!size) return "Unknown";
        const bytes = parseInt(size, 10);
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Bytes";
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    };

    const formatDate = (value) => {
        if (value) {
            const date = new Date(value);

            // Format the date
            const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

            // Convert to the desired format
            return date.toLocaleString('en-us', options).replace(',', '');
        } else {
            return '-';
        }
    }

    const extractKeysFromUrls = (urls) => {
        return urls.map((url) => {
            const urlParts = url.split('/');
            return urlParts[urlParts.length - 1]; // Extract the last part of the URL as the key
        });
    };

    const s3 = new AWS.S3({
        accessKeyId: ACCESS_KEY, // Replace with your access key
        secretAccessKey: SECRET_ACCESS_KEY, // Replace with your secret key
        region: REGION // Replace with your AWS region
    });

    // Function to get file sizes
    const getFileSizes = async (keys) => {
        try {
            const fileSizes = await Promise.all(
                keys.map(async (key) => {
                    const params = {
                        Bucket: S3_BUCKET,
                        Key: key
                    };
                    const metadata = await s3.headObject(params).promise();
                    return {
                        size: metadata.ContentLength // File size in bytes
                    };
                })
            );
            return fileSizes;
        } catch (error) {
            console.error('Error fetching file sizes:', error);
            throw error;
        }
    };

    const columnDefs = [
        {
            field: "title",
            headerName: "TITLE",
            cellRenderer: (params) => {
                // const { title, createdDate, lastModified } = params.data;
                return (

                    <div className="row-data-element">
                        <NoteViewTooltip data={params.data} />
                    </div>
                );
            },
            flex: 2,
            minWidth: 230
        },
        {
            field: "attachment",
            headerName: "ATTACHMENT",
            cellRenderer: (params) => {
                const { fileSizes } = params.data;

                return (
                    <div className="row-data-element">
                        <NoteAttachmentTooltip data={params.data} />
                        {/* <div className="attachments">1 {attachmentSizes}</div> */}
                    </div>
                );
            },
            flex: 1,
            minWidth: 90
        },
        {
            field: "createdDate",
            headerName: "CREATED DATE",
            cellRenderer: (params) => {
                const { createdDate } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="created-date">{formatDate(createdDate)}</div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 210
        },
        {
            field: "updatedAt",
            headerName: "LAST MODIFIED",
            cellRenderer: (params) => {
                const { updatedAt } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="created-date">{formatDate(updatedAt)}</div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 210
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { noteId } = params.data;
                return (
                    <div className='row-data-element'>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="edit"
                                        onClick={() => handleEditOpen(noteId)}
                                    >
                                        Edit
                                    </Menu.Item>
                                    <Menu.Item key="delete"
                                        onClick={() => handleDeleteNote(noteId)}>Delete</Menu.Item>

                                </Menu>
                            }
                            trigger={["click"]}
                        >
                            <img src={moreIcon} className="more-icon" />
                        </Dropdown>

                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            // flex: 1,
            minWidth: 150
        }
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No notes to display</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            document.getElementById("filter-text-box").value
        );
    }, []);

    return (
        <div className="notes-container">
            <div className="notes-header">
                <span className="notes-text">Notes</span>
                <div className="add-note" onClick={() => setAddShow(true)}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-note-text">Note</span>
                </div>
            </div>

            {loading && (
                <Spin />
            )}

            {!loading && (
                <div style={gridStyle} className="ag-theme-quartz">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onFirstDataRendered={onFirstDataRendered}
                        tooltipShowDelay={0}
                        rowHeight={rowHeight}
                        gridOptions={gridOptions}
                    />
                </div>
            )}

            <NotesOffcanvas show={addShow} setShow={setAddShow} mode={'add'} data={staffId} getNotes={getNotes} />
            <NotesOffcanvas show={editShow} setShow={setEditShow} mode={'edit'} data={note} getNotes={getNotes} />

            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Note
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a note,
                            this will remove all the note data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteNote}>Yes</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Notes;