import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from 'antd';  // Using Ant Design Tooltip

const CellWithOverflowTooltip = ({ value }) => {
    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const hasOverflow = textRef.current.scrollWidth > textRef.current.clientWidth;
                setIsOverflowing(hasOverflow);
            }
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);  // Recheck on resize

        return () => window.removeEventListener('resize', checkOverflow);
    }, [value]);

    return (
        <Tooltip title={isOverflowing ? value : ''}>
            <div
                ref={textRef}
                className='ellipsis-cell'
            >
                {value}
            </div>
        </Tooltip>
    );
};

export default CellWithOverflowTooltip;