import { AgGridReact } from "ag-grid-react";
import { Button, Modal, Spin } from "antd";
import { useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import { routes_patient_details } from "../../../../utils/RoutingConts";
import addIcon from "../../../../assets/add.png";
import closeIcon from "../../../../assets/close.png";
import deleteIcon from "../../../../assets/delete.png";
import editIcon from "../../../../assets/edit.png";
import infoIcon from "../../../../assets/info.png";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";

const EligibilityCheck = () => {
    const Token = localStorage.getItem("Token");
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const { patientId } = useParams();
    const [selectedDate, setSelectedDate] = useState(null);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [staffDetails, setStaffDetails] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [appointmentId, setAppointmentId] = useState();
    const [patient, setPatient] = useState([]);

    const tableData = [
        {
            verificationId: "EC12401",
            verifiedOn: "09 Sep 2024",
            purpose: "Dental Crown Implant",
            appointmentId: "090912",
        },
        {
            verificationId: "EC12391",
            verifiedOn: "09 Sep 2024",
            purpose: "Dental Cleaning",
            appointmentId: "090910",
        },
        {
            verificationId: "EC12370",
            verifiedOn: "01 Sep 2024",
            purpose: "Cosmetic Procedures",
            appointmentId: "010904",
        },
        {
            verificationId: "EC12368",
            verifiedOn: "01 Sep 2024",
            purpose: "Fillings",
            appointmentId: "010910",
        },
        {
            verificationId: "EC12361",
            verifiedOn: "01 Sep 2024",
            purpose: "Root Canal Treatment",
            appointmentId: "160911",
        },
        {
            verificationId: "EC12358",
            verifiedOn: "16 Aug 2024",
            purpose: "Dental Cleaning",
            appointmentId: "010803",
        },
        {
            verificationId: "EC12336",
            verifiedOn: "06 Aug 2024",
            purpose: "Periodontal Treatment",
            appointmentId: "060810",
        },
        {
            verificationId: "EC11276",
            verifiedOn: "01 Aug 2024",
            purpose: "DCT - Imaging",
            appointmentId: "010803",
        },
    ];

    const handleAddOpen = () => {
        setAddShow(true);
    };

    const handleEditOpen = () => {
        setEditShow(true);
    }

    const columnDefs = [
        {
            field: "verificationId",
            headerName: "VERIFICATION ID",
            cellRenderer: (params) => {
                const { verificationId } = params.data;
                return (
                    <div className="row-data-element">
                        <Link to={`${routes_patient_details}/${patientId}/eligibility-check/${verificationId}`}>
                            <span className="app-id">{verificationId}</span>
                        </Link>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "verifiedOn",
            headerName: "VERIFIED ON",
            cellRenderer: (params) => {
                const { verifiedOn } = params.data;
                return (
                    <div className="row-data-element">
                        {verifiedOn}
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "purpose",
            headerName: "PURPOSE",
            cellRenderer: (params) => {
                const { purpose } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="full-name">
                            <CellWithOverflowTooltip value={purpose} />
                        </div>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "appointmentId",
            headerName: "ATTACHMENT ID",
            cellRenderer: (params) => {
                const { appointmentId } = params.data;
                return (
                    <div className="row-data-element">
                        {appointmentId}
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { verificationId } = params.data;
                return (
                    <div className='actions'>
                        <img src={editIcon} alt="Edit" onClick={() => handleEditOpen(verificationId)} />
                        <img src={deleteIcon} alt="Delete" onClick={() => handleEligibilityDelete(verificationId)} />
                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            minWidth: 88,
        }
    ]

    const defaultColDef = useMemo(() => {
        return {
            flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const rowSelection = useMemo(() => {
        return {
            mode: "multiRow",
        };
    }, []);

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No appointments to display</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    const handleEligibilityDelete = () => {

    }

    const deleteEligibilityCheck = () => {

    }

    return (
        <div className="appointments-container">
            <div className="appointments-header">
                <span className="appointment-text">Eligibility Check</span>
                <div className="add-appointment" onClick={() => { handleAddOpen() }}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-text">Eligibility Check</span>
                </div>
            </div>
            <div className="appointment-list">
                {loading && (
                    <Spin className="spinner" />
                )}

                {!loading && appointments && (
                    <div style={gridStyle} className="ag-theme-quartz">
                        <AgGridReact
                            ref={gridRef}
                            rowData={tableData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onFirstDataRendered={onFirstDataRendered}
                            tooltipShowDelay={0}
                            rowSelection={rowSelection}
                            rowHeight={rowHeight}
                            gridOptions={gridOptions}
                        />
                    </div>
                )}

            </div>


            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Appointment
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a appointment,
                            this will remove all the appointment data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteEligibilityCheck}>Yes</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default EligibilityCheck;