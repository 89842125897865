import React, { useEffect, useImperativeHandle, useState } from "react";
import { message, Select, Space, Table, Button, Row, Spin } from "antd";
import axios from "axios";
import API_BASE_URL from "../../../env";

const AccessControl = ({
  ref,
  selectedStaffRoleId,
  setSelectedStaffRoleId,
  selectedStaffRoleName,
  setSelectedStaffRoleName,
  setRoles,
  roles,
}) => {
  const Token = localStorage.getItem("Token");
  const clinicId = Number(localStorage.getItem("clinic_id")); // Ensure clinicId is a number
  const [spinner, setSpinner] = useState(false);
  // const [selectedStaffRoleId, setSelectedStaffRoleId] = useState(null);
  // const [roles, setRoles] = useState([]);
  const [tableData, setTableData] = useState([
    {
      key: "1",
      moduleName: "Appointments",
      canView: "all",
      canEdit: "all",
      canDelete: "all",
    },
    {
      key: "2",
      moduleName: "Patients",
      canView: "all",
      canEdit: "all",
      canDelete: "all",
    },
    {
      key: "3",
      moduleName: "Staffs",
      canView: "all",
      canEdit: "all",
      canDelete: "all",
    },
    {
      key: "4",
      moduleName: "Claims",
      canView: "all",
      canEdit: "all",
      canDelete: "all",
    },
    {
      key: "5",
      moduleName: "Clinic Management",
      canView: "all",
      canEdit: "all",
      canDelete: "all",
    },
  ]);
  const [originalTableData, setOriginalTableData] = useState([]); // For cancel functionality

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  // Use `useImperativeHandle` to expose methods
  useImperativeHandle(ref, () => ({
    fetchRoles,
  }));

  console.log(roles);

  // Fetch roles from the API
  const fetchRoles = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/role/${clinicId}`,
        config
      );
      const formattedRoles = response.data.data.map((role) => ({
        value: role.role_id,
        label: role.staff_role,
      }));
      setRoles(formattedRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
      message.error("Failed to fetch roles.");
    } finally {
      setSpinner(false);
    }
  };

  // Fetch role permissions based on selected role
  const fetchRolePermissions = async () => {
    if (selectedStaffRoleId) {
      setSpinner(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/role-access/fetchRolePermissions?staffRoleId=${selectedStaffRoleId}`,
          config
        );
        const backendData = response.data.data;

        // Merge backend permissions with static table data
        const updatedTableData = tableData.map((module) => {
          const matchedModule = backendData.find(
            (backendModule) => backendModule.moduleName === module.moduleName
          );
          return {
            ...module,
            canView: matchedModule
              ? matchedModule.canView
                ? "all"
                : "none"
              : "all",
            canEdit: matchedModule
              ? matchedModule.canEdit
                ? "all"
                : "none"
              : "all",
            canDelete: matchedModule
              ? matchedModule.canDelete
                ? "all"
                : "none"
              : "all",
          };
        });

        setTableData(updatedTableData);
        setOriginalTableData(updatedTableData); // Save original state for cancel functionality
      } catch (error) {
        console.error("Error fetching role permissions:", error);
        message.error("Failed to fetch role permissions.");
      } finally {
        setSpinner(false);
      }
    }
  };

  // Handle role selection
  const handleRoleChange = (value, key) => {
    setSelectedStaffRoleId(value);
    setSelectedStaffRoleName(key.label);
    console.log("label", key.label);
  };

  // Update permissions state when a value changes
  const handlePermissionChange = (moduleName, permissionType, value) => {
    setTableData((prev) =>
      prev.map((module) =>
        module.moduleName === moduleName
          ? {
              ...module,
              [permissionType]: value,
            }
          : module
      )
    );
  };

  // Submit data to the POST API
  const submitPermissions = async () => {
    if (!selectedStaffRoleId) {
      message.error("Please select a role before submitting.");
      return;
    }

    const payload = {
      staffRoleId: selectedStaffRoleId,
      clinicId,
      modulePermissions: tableData.map(
        ({ moduleName, canView, canEdit, canDelete }) => ({
          moduleName,
          canView: canView === "all",
          canEdit: canEdit === "all",
          canDelete: canDelete === "all",
        })
      ),
    };

    try {
      await axios.post(
        `${API_BASE_URL}/api/v1/role-access/createStaffRole`,
        payload,
        config
      );
      message.success("Permissions updated successfully!");
      fetchRolePermissions();
    } catch (error) {
      console.error("Error submitting permissions:", error);
      message.error("Failed to update permissions.");
    }
  };

  // Cancel and reset to original state
  const cancelChanges = () => {
    setTableData(originalTableData);
    message.info("Changes have been reverted.");
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchRolePermissions();
  }, [selectedStaffRoleId]);

  const columns = [
    {
      title: <span className="table-header-names">Modules</span>,
      dataIndex: "moduleName",
      key: "moduleName",
      width: "25%",
    },
    {
      title: <span className="table-header-names">View</span>,
      dataIndex: "canView",
      key: "canView",
      render: (value, record) => (
        <Select
          className="select-role-dropdown"
          value={value}
          onChange={(newValue) =>
            handlePermissionChange(record.moduleName, "canView", newValue)
          }
          bordered={false}
          options={[
            { value: "all", label: "All" },
            { value: "none", label: "None" },
          ]}
        />
      ),
      width: "25%",
    },
    {
      title: <span className="table-header-names">Edit</span>,
      dataIndex: "canEdit",
      key: "canEdit",
      render: (value, record) => (
        <Select
          className="select-role-dropdown"
          value={value}
          onChange={(newValue) =>
            handlePermissionChange(record.moduleName, "canEdit", newValue)
          }
          bordered={false}
          options={[
            { value: "all", label: "All" },
            { value: "none", label: "None" },
          ]}
        />
      ),
      width: "25%",
    },
    {
      title: <span className="table-header-names">Delete</span>,
      dataIndex: "canDelete",
      key: "canDelete",
      render: (value, record) => (
        <Select
          className="select-role-dropdown"
          value={value}
          onChange={(newValue) =>
            handlePermissionChange(record.moduleName, "canDelete", newValue)
          }
          options={[
            { value: "all", label: "All" },
            { value: "none", label: "None" },
          ]}
          bordered={false}
        />
      ),
      width: "25%",
    },
  ];

  return (
    <div className="access-control-main-div">
      <Spin spinning={spinner && true}>
        <Row
          className="d-flex flex-col"
          style={{ gap: 8, padding: "16px 0 0 0" }}
        >
          <span className="select-role-text">Select Role</span>
          <Select
            bordered={false}
            placeholder="Select a role"
            style={{ width: 200, marginBottom: 16 }}
            onChange={handleRoleChange}
            onClick={fetchRoles}
            options={roles}
            loading={spinner}
            className="select-role-dropdown"
            value={selectedStaffRoleName}
          />
        </Row>
        {selectedStaffRoleId ? (
          <Table columns={columns} dataSource={tableData} pagination={false} />
        ) : (
          <Table columns={columns} dataSource={[]} pagination={false} />
        )}
        {selectedStaffRoleId && (
          <Space style={{ marginTop: 16 }}>
            <Button type="primary" onClick={submitPermissions}>
              Submit
            </Button>
            <Button onClick={cancelChanges}>Cancel</Button>
          </Space>
        )}
      </Spin>
    </div>
  );
};

export default AccessControl;
