import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { message, Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import moreIcon from "../../../../../assets/expand_more.png";
import CellWithOverflowTooltip from "../../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../../../../../env";
import ReviewOffcanvas from "./reviewOffcanvas/ReviewOffcanvas";

const Patients = () => {
    const Token = localStorage.getItem("Token");
    const gridRef = useRef(null);
    const gridStyle = useMemo(() => ({ height: "calc(100% - 68px)", width: "100%" }), []);
    const [rowData, setRowData] = useState([]);
    const [patient, setPatient] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/patients/get_all_patients`, config)
            .then(response => {
                setRowData(response.data);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error || "Failed to fetch the data");
                setLoading(false);
            })
    }, [!show])

    const handleOpen = (id) => {
        const selectedPatient = rowData?.filter((item) => item.patientId === id);
        setPatient(selectedPatient);
        setShow(true);
    };

    const copyToClipboard = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => {
                message.success("Email copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy email!");
            });
    };

    const getInitials = (name) => {
        const nameParts = name.split(" ");
        const initials =
            nameParts[0][0].toUpperCase() + (nameParts[1]?.[0]?.toUpperCase() || "");
        return initials;
    };

    const columnDefs = [
        {
            field: "patientId",
            headerName: "PID",
            cellRenderer: (params) => {
                const { patientId } = params.data;
                return (
                    <div className="row-data-element">
                        {patientId}
                    </div>
                );
            },
            flex: 1,
            minWidth: 80
        },
        {
            field: "full_name",
            headerName: "NAME",
            cellRenderer: (params) => {
                const { firstname, lastname } = params.data;
                const full_name = `${firstname} ${lastname}`;
                return (
                    <div className="row-data-element">
                        <div className="name-container">
                            <div className="initials">
                                {getInitials(full_name)}
                            </div>
                            <div className="full-name">
                                <CellWithOverflowTooltip value={full_name} />
                            </div>
                        </div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 230
        },
        {
            field: "birthdate",
            headerName: "AGE",
            cellRenderer: (params) => {
                const { birthdate } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="age">{birthdate ? new Date().getFullYear() - new Date(birthdate).getFullYear() : '-'}</div>
                    </div>
                );
            },
            flex: 1,
            minWidth: 90
        },
        {
            field: "contact",
            headerName: "CONTACT",
            cellRenderer: (params) => {
                const { cell, email } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="phone">{cell}</div>
                        <div className="email" onClick={(() => copyToClipboard(email))}><CellWithOverflowTooltip value={email} /></div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 250
        },
        {
            field: "created_at",
            headerName: "Last VISIT & Treatment",
            cellRenderer: (params) => {
                const { created_at } = params.data;
                const created_date = moment(created_at).format("DD MMM YYYY, h:mm A");
                return (
                    <div className="row-data-element">
                        <div className="created-date">{created_date}</div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 210
        },
        {
            field: "status",
            headerName: "STATUS",
            cellRenderer: (params) => {
                const { status } = params.data;
                let statusClass = "";

                if (status === "PENDING") {
                    statusClass = "status-pending"; // Pending tab
                } else {
                    statusClass = "status-active";  // Trial and subscriptions tab
                }

                return (
                    <div className="row-data-element">
                        <div className={`status ${statusClass}`}>
                            {status === "PENDING" ? "PENDING" : "APPROVED"}
                        </div>
                    </div>
                );
            },
            flex: 1.25,
            minWidth: 150
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { patientId } = params.data;
                return (
                    <div className='row-data-element'>
                        <div className='add-review-text' onClick={() => handleOpen(patientId)}>Add Review</div>
                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            // flex: 1,
            minWidth: 150
        }
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const rowSelection = useMemo(() => {
        return {
            mode: "multiRow",
        };
    }, []);

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No patients to display</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            document.getElementById("filter-text-box").value
        );
    }, []);

    return (
        <div className="patients-container">
            <div className="patients-header">
                <span className="patients-text">Patients</span>
                {/* <div className="patient-dropdown">
                    <Select defaultValue="all" suffixIcon={<img src={moreIcon} />}>
                        <Option value="all">ALL</Option>
                        <Option value="recent">RECENT</Option>
                    </Select>
                </div> */}
            </div>

            {loading && (
                <Spin className="spinner" />
            )}

            {!loading && (
                <div style={gridStyle} className="ag-theme-quartz">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onFirstDataRendered={onFirstDataRendered}
                        tooltipShowDelay={0}
                        rowSelection={rowSelection}
                        rowHeight={rowHeight}
                        gridOptions={gridOptions}
                    />
                </div>
            )}

            <ReviewOffcanvas show={show} setShow={setShow} mode={'add'} data={patient} />
        </div>
    )
}

export default Patients;