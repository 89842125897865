import {
  CloseOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import add_alt from "../../assets/add.png";
import default_propic from "../../assets/default_propic.png";
import moreVertical from "../../assets/more_vert.png";
import sync_alt from "../../assets/sync.png";
import usflag from "../../assets/usflag.png";
import API_BASE_URL from "../../env";
import { routes_patient_details } from "../../utils/RoutingConts";
import "./Patients.css";
import AppointmentOffcanvas from "../staff/staff-details/tabs/appointments/appointmentOffcanvas/AppointmentOffcanvas";
import TabPane from "antd/es/tabs/TabPane";
import { AgGridReact } from "ag-grid-react";
import CellWithOverflowTooltip from "../CellWithOverflowTooltip/CellWithOverflowTooltip";
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import ProfileImage from "./patientdetails/ProfileImage";

const Patients = () => {
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const gridRef = useRef();
  const gridStyle = useMemo(
    () => ({ height: "calc(100% - 74px)", width: "100%" }),
    []
  );
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patient, setPatient] = useState();
  const [show, setShow] = useState(false);
  const [syncTime, setSyncTime] = useState();

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const handleBookAppointment = (id) => {
    setShow(true);
    const patient = patients.filter((item) => item.patientId === id);
    setPatient(patient);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/patients/get_all_patients`,
        config
      );
      const updatedData = response.data.map((item) => {
        if (item.firstname && item.lastname) {
          return { ...item, name: `${item.firstname} ${item.lastname}` };
        }
        return { ...item, name: item.firstname };
      });
      setPatients(updatedData);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch patients.");
    } finally {
      setLoading(false);
    }
  };

  const [editRecord, setEditRecord] = useState(null);

  const handleEdit = (record) => {
    setEditRecord(record);
    setDrawerVisible(true);
    form.setFieldsValue({
      ...record,
      addressLine1: record.addressLine1,
      age: new Date().getFullYear() - new Date(record.birthdate).getFullYear(),
      primaryPhone: record.cell,
      // dob: moment(record.birthdate).format("YYYY-MM-DD"),
      dob: record.birthdate ? dayjs(record.birthdate) : null,
      referredBy: record.patientReferral ? record.patientReferral : null,
      emergencyContactName: record.guarantorFirstName
        ? record.guarantorFirstName
        : null,
      emergencyContactPhone: record.preferredContact
        ? record.preferredContact
        : null,
    });
  };

  const handleDelete = (patientId) => {
    axios
      .delete(
        `${API_BASE_URL}/api/v1/patients/delete_patient?patientId=${patientId}`,
        config
      )
      .then((res) => {
        message.success("Patient deleted successfully!");
        fetchPatients(); // Refresh the patient list after deletion
      })
      .catch((error) => {
        console.error("Error deleting patient:", error);
        message.error("Failed to delete patient.");
      });
  };

  // Fetch all patients
  useEffect(() => {
    fetchPatients();
    localStorage.removeItem("activeKey");

    if (localStorage.getItem('patient_sync_time')) {
      setSyncTime(localStorage.getItem('patient_sync_time'));
    } else {
      setSyncTime(moment().format("DD-MM-YYYY hh:mm A"));
      localStorage.setItem('patient_sync_time', moment().format("DD-MM-YYYY hh:mm A"));
    }
  }, []);

  const copyToClipboard = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        message.success("Email copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy email!");
      });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("91")) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, "IN");
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    } else {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US");
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    }
  };

  const columnDefs = [
    {
      field: "patientId",
      headerName: "PATIENT ID",
      cellRenderer: (params) => {
        const { patientId } = params.data;
        return <div className="row-data-element">{patientId}</div>;
      },
      flex: 1.25,
      minWidth: 120,
    },
    {
      field: "name",
      headerName: "NAME",
      cellRenderer: (params) => {
        const { name, patientId, firstname, lastname } = params.data;
        return (
          <div className="row-data-element">
            <div className="name-container">
              {patients?.profilePictureUrl ? (
                <ProfileImage
                  profile_picture_url={
                    patients.profilePictureUrl
                      ? patients.profilePictureUrl?.split(".com/")[1]
                      : null
                  }
                />
              ) : (
                <div className="initials">
                  {`${firstname?.charAt(0).toUpperCase()}${(lastname
                    ? lastname
                    : ""
                  )
                    .charAt(0)
                    .toUpperCase()}`}
                </div>
              )}
              <div className="full-name">
                <Link to={`${routes_patient_details}/${patientId}`}>
                  <CellWithOverflowTooltip value={name} />
                </Link>
              </div>
            </div>
          </div>
        );
      },
      // flex: 2,
      minWidth: 220,
    },
    {
      field: "age",
      headerName: "AGE",
      cellRenderer: (params) => {
        const { birthdate } = params.data;
        return (
          <div className="row-data-element">
            <div className="age">
              {birthdate
                ? `${new Date().getFullYear() - new Date(birthdate).getFullYear()
                } Years`
                : "-"}
            </div>
          </div>
        );
      },
      // flex: 1,
      minWidth: 100,
    },
    {
      field: "contact",
      headerName: "CONTACT",
      cellRenderer: (params) => {
        const { cell, email } = params.data;
        return (
          <div className="row-data-element">
            <div className="phone">{formatPhoneNumber(cell)}</div>
            <div className="email" onClick={() => copyToClipboard(email)}>
              <CellWithOverflowTooltip value={email} />
            </div>
          </div>
        );
      },
      // flex: 2,
      minWidth: 200,
    },
    {
      field: "addressLine1",
      headerName: "ADDRESS",
      cellRenderer: (params) => {
        const { addressLine1 } = params.data;
        return (
          <div className="row-data-element">
            <CellWithOverflowTooltip value={addressLine1} />
          </div>
        );
      },
      // flex: 2,
      minWidth: 200,
    },
    {
      field: "created_at",
      headerName: "CREATED DATE",
      cellRenderer: (params) => {
        const { created_at } = params.data;
        const created_date = moment(created_at).format("DD MMM YYYY");
        const created_time = moment(created_at).format("h:mm A");
        return (
          <div className="row-data-element">
            <span className="created-date">{created_date}</span>
            <span className="created-date">{created_time}</span>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 220,
    },
    {
      field: "lastVisit",
      headerName: "LAST VISIT",
      cellRenderer: (params) => {
        const { lastVisit } = params.data;
        const last_visit_date = lastVisit
          ? moment(lastVisit).format("DD MMM YYYY")
          : "-";
        const last_visit_time = lastVisit
          ? moment(lastVisit).format("h:mm A")
          : "-";
        return (
          <div className="row-data-element">
            <span className="created-date">{last_visit_date}</span>
            <span className="created-date">{last_visit_time}</span>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 220,
    },
    {
      field: "dental_history",
      headerName: "DENTAL HISTORY",
      cellRenderer: (params) => {
        const { status } = params.data;

        return (
          <div className="row-data-element">
            <div>Dental Pain/Discomfort</div>
          </div>
        );
      },
      // flex: 1.25,
      minWidth: 180,
    },
    {
      field: "status",
      headerName: "STATUS",
      cellRenderer: (params) => {
        const { status } = params.data;
        let statusClass = "";

        if (status === "INACTIVE") {
          statusClass = "status-pending"; // Pending tab
        } else {
          statusClass = "status-active"; // Trial and subscriptions tab
        }

        return (
          <div className="row-data-element">
            <div className={`status ${statusClass}`}>{status}</div>
          </div>
        );
      },
      // flex: 1.25,
      minWidth: 180,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        const { patientId } = params.data;
        return (
          <div className="actions">
            <div
              className="book-app-text"
              onClick={() => {
                handleBookAppointment(patientId);
              }}
            >
              Book Appointment
            </div>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      handleEdit(params.data);
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      handleDelete(patientId);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <img src={moreVertical} alt="More" className="more" />
            </Dropdown>
          </div>
        );
      },
      pinned: "right", // This fixes the column to the right side
      suppressMovable: true, // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
      minWidth: 200,
      // maxWidth: 100
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  const rowHeight = 80;

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No patients to display</span>',
  };

  const onFirstDataRendered = (params) => {
    params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
  };

  const showModal = () => {
    setDrawerVisible(true);
  };

  const handleCancel = () => {
    form.resetFields(); // Clear form fields
    setDrawerVisible(false);
    setPreviewURL("");
    setSelectedFile(null);
    setLoading(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file)); // Create a URL for the thumbnail preview
    } else {
      message.success("Please upload a valid JPG or PNG image.");
      setSelectedFile(null);
      setPreviewURL("");
    }
  };

  const [disbaledButton, setdisbaledButton] = useState(false);

  const handleSubmit = (values) => {
    setdisbaledButton(true);
    setLoading(true);
    const payload = {
      firstname: values.name.split(" ")[0],
      middlename: values.middlename || "",
      lastname: values.name.split(" ")[1],
      preferredName: values.name || "",
      salutation: values.salutation || "",
      birthdate: values.dob.format("YYYY-MM-DDTHH:mm:ss"),
      status: "Active",
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2 || "",
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      cell: values.primaryPhone,
      email: values.email,
      firstVisit: "",
      lastVisit: "",
      providerId: "1",
      practiceId: "1",
      preferredContact: values.emergencyContactPhone,
      preferredCommunicationMethod: "",
      custId: "",
      otherReferral: values.otherReferral || "",
      patientReferral: values.referredBy || "",
      referredOut: "",
      guarantorId: "",
      guarantorFirstName: values.emergencyContactName || "",
      guarantorLastName: "",
    };

    if (editRecord) {
      axios
        .put(
          `${API_BASE_URL}/api/v1/patients/update_patient?patientId=${editRecord.patientId}`,
          payload,
          config
        )
        .then((res) => {
          message.success("Patient updated successfully!");
          setEditRecord(null);
          setDrawerVisible(false);
          form.resetFields();
          setLoading(false);
          setdisbaledButton(false);
          fetchPatients();
          if (res.data.data.patientId) {
            const patient_id = res.data.data.patientId;
            if (!selectedFile) {
              // message.warning("Please select an image before submitting.");
              return;
            }

            const formData = new FormData();
            formData.append("file", selectedFile);
            axios
              .put(
                `${API_BASE_URL}/api/v1/patients/patientId/update-profile-picture?patientId=${patient_id}`,
                formData,
                config,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                message.success("Profile picture uploaded successfully!");
                setPreviewURL("");
                setSelectedFile(null);
                setLoading(false);
                fetchPatients();
                setdisbaledButton(false);
              })
              .catch((error) => {
                setLoading(false);
                message.error("Failed to upload profile picture.");
              });
          }
        })
        .catch((error) => {
          message.error("Failed to add patient.");
          setLoading(false);
        });
    } else {
      axios
        .post(`${API_BASE_URL}/api/v1/patients/add-patient`, payload, config)
        .then((res) => {
          message.success("Patient added successfully!");
          fetchPatients();
          setDrawerVisible(false);
          form.resetFields();
          setdisbaledButton(false);
          if (res.data.data.patientId) {
            const patient_id = res.data.data.patientId;
            if (!selectedFile) {
              // message.warning("Please select an image before submitting.");
              return;
            }

            const formData = new FormData();
            formData.append("file", selectedFile);
            axios
              .post(
                `${API_BASE_URL}/api/v1/patients/patientId/upload-profile-picture?patientId=${patient_id}`,
                formData,
                config,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                setdisbaledButton(false);
                message.success("Profile picture uploaded successfully!");
                setPreviewURL("");
                setSelectedFile(null);
                setLoading(false);
                fetchPatients();
              })
              .catch((error) => {
                setLoading(false);
                message.error("Failed to upload profile picture.");
              });
          }
          setLoading(false);
          setdisbaledButton(false);
        })
        .catch((err) => {
          message.error(err.response?.data.data.errors[0]);
          setLoading(false);
        });
    }
  };

  const extraContent = (
    <Space>
      <Button
        onClick={() => {
          const payload = {};
          axios
            .post(
              `${API_BASE_URL}/api/v1/patients/sync-and-save`,
              payload,
              config
            )
            .then((res) => {
              message.success("Sync Success!");
              const time = res.data[0].updatedAt;
              localStorage.setItem('patient_sync_time', moment(time).format("DD-MM-YYYY hh:mm A"));
              fetchPatients();
              setSyncTime(localStorage.getItem('patient_sync_time'));
            })
            .catch((err) => {
              message.error("error occured");
            });
        }}
        type="text"
        icon={<img alt="" src={sync_alt} style={{ width: 24, height: 24 }} />}
      >
        <span className="sync-text">Sync</span>
      </Button>
      <Button
        type="text"
        onClick={showModal}
        icon={<img alt="" src={add_alt} style={{ width: 24, height: 24 }} />}
      >
        <span className="sync-text">Patient</span>
      </Button>
      <Input
        suffix={<SearchOutlined />}
        placeholder="SEARCH"
        className="search-input"
      />
    </Space>
  );

  const onTabsChange = (activeKey) => {
    console.log(activeKey);
  };

  // const [patientShow, setPatientShow] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const closeDrawer = () => {
    setDrawerVisible(false);
    setEditRecord(null);
    form.resetFields();
    setPreviewURL("");
    setSelectedFile(null);
    setLoading(false);
  };

  return (
    <div className="patients-main-div">
      <div className="data-container">
        <span className="sync-time">Last updated: {syncTime}</span>
        <Tabs
          defaultActiveKey="1"
          // items={items}
          tabBarExtraContent={extraContent}
          onChange={onTabsChange}
        >
          <TabPane tab="All" key="1" />
        </Tabs>
        <Divider plain></Divider>

        {loading && (
          <Spin className="spinner" />
        )}

        {!loading && (
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              rowData={patients}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50, 100]}
              onFirstDataRendered={onFirstDataRendered}
              tooltipShowDelay={0}
              rowSelection={rowSelection}
              rowHeight={rowHeight}
              gridOptions={gridOptions}
            />
          </div>
        )}
      </div>
      <Drawer
        className="add-patient-modal"
        closeIcon={false}
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="add-patient-modal-main-div w-100">
          <Row className="w-100 d-flex jc-end">
            <CloseOutlined style={{ fontSize: 24 }} onClick={closeDrawer} />
          </Row>
          <Row>
            <span className="add-patient-text">
              {editRecord ? "Edit Patient" : "Add Patient"}
            </span>
          </Row>

          <Spin size="large" spinning={loading}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              className="w-100"
            >
              <Row gutter={[0, 24]}>
                {editRecord && (
                  <Row
                    className="w-100 d-flex ai-end"
                    style={{ padding: "20px 0 0 0" }}
                  >
                    <input
                      id="fileInput"
                      type="file"
                      accept=".jpg,.png"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {previewURL ? (
                      <div style={{ marginTop: "10px" }}>
                        <img
                          src={previewURL}
                          alt="Thumbnail Preview"
                          style={{
                            objectFit: "cover",
                            width: 100,
                            borderRadius: "10px",
                            height: 100,
                          }}
                        />
                      </div>
                    ) : patients ? (
                      <ProfileImage
                        profile_picture_url={
                          editRecord?.profilePictureUrl &&
                          editRecord?.profilePictureUrl.split(".com/")[1]
                        }
                      />
                    ) : (
                      <img
                        alt=""
                        src={default_propic}
                        style={{
                          borderRadius: "10px",
                          width: 58,
                          height: 58,
                        }}
                      />
                    )}
                    <Button
                      type="text"
                      style={{ margin: "0 0 0 10px" }}
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      <img src={add_alt} alt="" style={{ width: 24 }} /> Photo
                    </Button>
                  </Row>
                )}

                <Row
                  gutter={[20, 24]}
                  className="w-100"
                  style={{ margin: "30px 0 0 0" }}
                >
                  <Col span={12}>
                    <span className="input-header">
                      Name <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="name"
                      rules={[
                        { required: true, message: "Please enter the name" },
                      ]}
                    >
                      <Input
                        className="input"
                        disabled={editRecord}
                        placeholder="Enter name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      {editRecord && (
                        <>
                          Patient ID <span style={{ color: "#F00" }}>*</span>
                        </>
                      )}
                    </span>
                    {editRecord && (
                      <Form.Item name="patientId">
                        <Input disabled className="input" />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Age(Yrs) <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="age"
                      rules={[
                        { required: true, message: "Please enter the age" },
                      ]}
                    >
                      <Input
                        className="input"
                        type="number"
                        disabled={editRecord}
                        placeholder="Enter age"
                      />
                    </Form.Item>
                  </Col>
                  {!editRecord && (
                    <Col span={12}>
                      <span className="input-header">
                        Gender <span style={{ color: "#F00" }}>*</span>
                      </span>
                      <Form.Item
                        name="gender"
                        rules={[
                          { required: true, message: "Please select gender" },
                        ]}
                      >
                        <Select
                          className="input"
                          bordered={false}
                          placeholder="Select gender"
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="other">Other</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={12}>
                    <span className="input-header">
                      Phone number(Primary){" "}
                      <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="primaryPhone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"us"}
                        onlyCountries={["us", "in"]}
                        inputClass="form-control"
                        className="input"
                      />
                    </Form.Item>
                  </Col>
                  {!editRecord && (
                    <Col span={12}>
                      <span className="input-header">Phone number</span>
                      <Form.Item
                        name="secondaryPhone"
                        rules={[{ required: false }]}
                      >
                        <PhoneInput
                          country={"us"}
                          onlyCountries={["us", "in"]}
                          className="input"
                          inputClass="form-control"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={12}>
                    <span className="input-header">
                      Date of birth <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "Please select date of birth",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        className="input"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Email <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter email" },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter email address"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Address <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="addressLine1"
                      rules={[
                        { required: true, message: "Please enter address" },
                      ]}
                    >
                      <Input.TextArea
                        className="input"
                        rows={2}
                        placeholder="Enter address"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">Referred by</span>
                    <Form.Item name="referredBy" rules={[{ required: false }]}>
                      <Select
                        className="input"
                        bordered={false}
                        placeholder="Select referral source"
                      >
                        <Option value="doctor">Doctor</Option>
                        <Option value="friend">Friend</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">Emergency contact name</span>
                    <Form.Item name="emergencyContactName">
                      <Input
                        className="input"
                        placeholder="Enter emergency contact name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Emergency contact phone number
                    </span>
                    <Form.Item name="emergencyContactPhone">
                      <PhoneInput
                        country={"us"}
                        onlyCountries={["us", "in"]}
                        className="input"
                        inputClass="form-control"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="w-100 d-flex jc-end" gutter={[16, 0]}>
                  <Col>
                    <Button key="cancel" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={disbaledButton}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Spin>
        </div>
      </Drawer>

      <AppointmentOffcanvas
        show={show}
        setShow={setShow}
        mode={"add"}
        inputData={patient}
        module={"patient"}
      />
    </div>
  );
};

export default Patients;
