import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthContextProvider } from "./context_api/ContextApi";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import Terms from "./signup/terms/Terms";
import SetPassword from "./signup/set_password/SetPassword";
import Resend from "./signup/resend/Resend";
import ForgotPassword from "./login/forgot_password/ForgotPassword";
import Home from "./components/home/Home";
import ProtectedRoute from "./protected-route/ProtectedRoute";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/resend" element={<Resend />} />
          <Route exact path="/set-password" element={<SetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/dashboard/*" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
