import { LoadingOutlined } from "@ant-design/icons";
import {
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CImage,
} from "@coreui/react";
import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import ivorylogo from "../assets/ivorylogo.png";
import silderimage from "../assets/silderimage.png";
import API_BASE_URL from "../env";
import { GOOGLE_API_KEY } from "../String";
import { routes_login, routes_terms } from "../utils/RoutingConts";
import "./SignUp.css";

const SignUp = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [terms, setTerms] = useState(true);
  const [spinner, setspinner] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const handleAdminSignup = (values) => {
    setspinner(true);
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      clinic_name: values.clinic_name,
      email: values.email,
      phone_number: values.phone_number,
      terms_accepted: terms,
      is_register_by: "self",
    };
    axios
      .post(`${API_BASE_URL}/api/v1/clinic/clinic-sign-up`, payload)
      .then(async (res) => {
        setTimeout(() => {
          setspinner(false);
          localStorage.setItem("email", values.email);
          navigate("/resend");
          // message.success("Successfully registered");
          form.resetFields();
        }, 500);
        // setSpinner(false);
      })
      .catch((err) => {
        setspinner(false);
        // message.error(err.response.data.errors);
        message.error(err.response.data.data.errors[0]);
      });
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const [phoneNumber, setPhoneNumber] = useState("+1");

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    // Ensure the input starts with +1 and only allows numbers after the country code
    if (value.startsWith("+1") && /^[+1]\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  return (
    <div className="signup-main-div">
      <Row className=" d-flex jc-center w-100 h-100">
        <Col xl={15} xxl={15} className="signup-slider-container">
          <CCarousel indicators>
            <CCarouselItem>
              <CImage alt="" src={silderimage} className="silderimage" />
              <CCarouselCaption className="ivory-description">
                <span className="slider-header-text w-100">
                  AI-Powered Dental Assessments
                </span>
                <span className="slider-subheader-text">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem>
              <CImage alt="" src={silderimage} className="silderimage" />
              <CCarouselCaption className="ivory-description">
                <span className="slider-header-text w-100">
                  AI-Powered Dental Assessments
                </span>
                <span className="slider-subheader-text">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem>
              <CImage alt="" src={silderimage} className="silderimage" />
              <CCarouselCaption className="ivory-description">
                <span className="slider-header-text w-100">
                  AI-Powered Dental Assessments
                </span>
                <span className="slider-subheader-text">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </CCarouselItem>
          </CCarousel>
        </Col>
        <Col
          sm={24}
          xl={9}
          xxl={9}
          className="d-flex flex-col jc-center signup-form-col"
        >
          <div className="signup-form-slider">
            <Row className="w-100 d-flex jc-center">
              <img alt="" src={ivorylogo} className="ivorylogo" />
            </Row>
            <Carousel autoplay autoplaySpeed={3000}>
              <div>
                <Row className="w-100 d-flex jc-center">
                  <span className="slider-header-text w-100">
                    AI-Powered Dental Assessments
                  </span>
                  <span className="slider-subheader-text">
                    Instantly analyze dental X-rays using advanced AI technology
                    to identify potential issues, recommend treatments, and
                    provide comprehensive reports.
                  </span>
                </Row>
              </div>
              <div>
                <Row className="w-100 d-flex jc-center">
                  <span className="slider-header-text w-100">
                    AI-Powered Dental Assessments
                  </span>
                  <span className="slider-subheader-text">
                    Instantly analyze dental X-rays using advanced AI technology
                    to identify potential issues, recommend treatments, and
                    provide comprehensive reports.
                  </span>
                </Row>
              </div>
              <div>
                <Row className="w-100 d-flex jc-center">
                  <span className="slider-header-text w-100">
                    AI-Powered Dental Assessments
                  </span>
                  <span className="slider-subheader-text">
                    Instantly analyze dental X-rays using advanced AI technology
                    to identify potential issues, recommend treatments, and
                    provide comprehensive reports.
                  </span>
                </Row>
              </div>
            </Carousel>
          </div>
          <Spin spinning={spinner}>
            <Row className="logo-container">
              <img alt="" src={ivorylogo} className="ivorylogo" />
            </Row>
            <Form
              className="w-100 d-flex justify-center"
              onFinish={handleAdminSignup}
              form={form}
            >
              <Row className="signup-form">
                <Row className="w-100 d-flex jc-center">
                  <span className="clinic-registration-text">
                    Clinic Registration
                  </span>
                </Row>
                <Row className="w-100">
                  <span className="input-header-text">First Name</span>
                  <Form.Item
                    className="w-100"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "First name cannot be empty",
                      },
                      {
                        min: 2,
                        message: "Minimum character length should be 2",
                      },
                      {
                        max: 50,
                        message:
                          "First name must be no more than 50 characters long",
                      },
                      {
                        validator: (_, value) => {
                          const onlyLetters = /^[A-Za-z]+$/;
                          if (value && !onlyLetters.test(value)) {
                            return Promise.reject(
                              new Error(
                                "First Name cannot include special characters"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      bordered={false}
                      className="signup-input-fields"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Row>
                <Row className="w-100">
                  <span className="input-header-text">Last Name</span>
                  <Form.Item
                    className="w-100"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Last name cannot be empty",
                      },
                      {
                        min: 2,
                        message: "Minimum character length should be 2",
                      },
                      {
                        max: 50,
                        message:
                          "Last name must be no more than 50 characters long",
                      },
                      {
                        validator: (_, value) => {
                          const onlyLetters = /^[A-Za-z]+$/;
                          if (value && !onlyLetters.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Last Name cannot include special characters"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      bordered={false}
                      className="signup-input-fields"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Row>
                <Row className="w-100">
                  <span className="input-header-text">Clinic Name</span>
                  <Form.Item
                    className="w-100"
                    name="clinic_name"
                    rules={[
                      {
                        required: true,
                        message: "Clinic name cannot be empty",
                      },
                      {
                        min: 2,
                        message: "Minimum character length should be 2 ",
                      },
                      {
                        max: 50,
                        message:
                          "Clinic name must be no more than 50 characters long",
                      },
                      {
                        validator: (_, value) => {
                          const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;
                          if (value && !onlyLettersAndSpaces.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Clinic Name cannot include special characters"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      bordered={false}
                      className="signup-input-fields"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Row>
                <Row className="w-100">
                  <span className="input-header-text">Work Email</span>
                  <Form.Item
                    className="w-100"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      {
                        pattern:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid email address",
                      },
                      {
                        max: 320,
                        message:
                          "Email address must be no more than 320 characters long",
                      },
                    ]}
                  >
                    <Input
                      bordered={false}
                      className="signup-input-fields"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Row>
                <Row className="w-100 signup-phone-field-row">
                  <span className="input-header-text">
                    Phone Number
                    <span className="asterisk">*</span>
                  </span>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                      {
                        pattern: /^[1-9]\d*$/,
                        message: "Phone number cannot start with a zero",
                      },
                      // { validator: validatePhoneNumber },
                    ]}
                  >
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us", "in"]}
                      inputClass="form-control"
                    />
                  </Form.Item>
                </Row>
                <Row
                  className="w-100 d-flex ai-center"
                  style={{
                    margin: "0.625vw 0 1.0417vw 0",
                    // margin: "1.0185185185185186vh 0 1.8518518518518519vh 0",
                  }}
                >
                  <Checkbox
                    onChange={() => {
                      setTerms(!terms);
                    }}
                  ></Checkbox>
                  <span className="terms-checkbox-text">
                    I agree to the{" "}
                    <span
                      className="terms-text"
                      onClick={() => {
                        window.open(routes_terms, "_blank");
                      }}
                    >
                      terms and services
                    </span>
                  </span>
                </Row>
                <Row className="w-100">
                  {/* <ReCAPTCHA
                    sitekey={GOOGLE_API_KEY} // Replace with your actual site key
                    onChange={handleCaptchaChange}
                  /> */}
                  <ReCAPTCHA
                    sitekey={GOOGLE_API_KEY}
                    onChange={handleCaptchaChange}
                  />
                </Row>
                <Row className="w-100 button-container">
                  <Button
                    className="register-button"
                    htmlType="submit"
                    disabled={terms || !captchaValue}
                  >
                    Register
                  </Button>
                </Row>
                <Row
                  className="w-100 d-flex jc-center"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="login-text-main"
                    onClick={() => {
                      navigate(routes_login);
                    }}
                  >
                    Already have account?{" "}
                    <span className="login-text">Login</span>
                  </span>
                </Row>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
