import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Space,
  Table,
  Spin,
  Modal,
  Row,
} from "antd";
import edit_alt from "../../../../assets/edit.png";
import delete_icon from "../../../../assets/delete.png";
import { UserAuth } from "../../../../context_api/ContextApi";
import moment from "moment";
import axios from "axios";
import API_BASE_URL from "../../../../env";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const Holidays = () => {
  const { openHoliday, setOpenHoliday } = UserAuth();
  const dateFormat = "YYYY-MM-DD";
  const [form] = Form.useForm();
  const { loading, setLoading } = UserAuth();
  const [formLoading, setFormLoading] = useState(false);
  const [Holidays, setHolidays] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [HolidayToDelete, setHolidayToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode
  const [editRecord, setEditRecord] = useState(null); // State to hold the record being edited
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  const columns = [
    {
      title: <span className="table-header-names">Holiday Name</span>,
      dataIndex: "event_name",
      key: "event_name",
      render: (text) => <span className="table-col-names">{text}</span>,
      width: "33%",
    },
    {
      title: <span className="table-header-names">Date</span>,
      dataIndex: "event_date",
      key: "event_date",
      width: "33%",
      align: "center",
    },
    {
      title: <span className="table-header-names">Actions</span>,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <img
            src={edit_alt}
            alt="Edit"
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => handleEdit(record)} // Trigger edit function
          />
          <img
            src={delete_icon}
            alt="Delete"
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => showDeleteModal(record)} // Trigger delete modal
          />
        </Space>
      ),
      width: "33%",
      align: "right",
    },
  ];

  const showDeleteModal = (record) => {
    setHolidayToDelete(record);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    const { event_id } = HolidayToDelete;
    const url = `${API_BASE_URL}/api/v1/events/${event_id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    try {
      const response = await axios.delete(url, config);
      if (response.status === 200) {
        message.success("Holiday deleted successfully!");
        fetchHolidays();
        setIsModalVisible(false);
      } else {
        message.error("Failed to delete Holiday.");
      }
    } catch (error) {
      message.error("Error deleting Holiday.");
      console.error(error);
    }
  };

  const handleEdit = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    setOpenHoliday(true);
    // Set initial form values with record details
    form.setFieldsValue({
      event_name: record.event_name,
      event_date: dayjs(record.event_date, dateFormat),
    });
  };

  const fetchHolidays = async () => {
    setLoading(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/events/${clinic_id}`, config)
      .then((res) => {
        setHolidays(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  useEffect(() => {
    fetchHolidays();
  }, [clinic_id, Token]);

  const onFinish = async (values) => {
    setFormLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const { event_name, event_date } = values;
    const formData = {
      eventDate: dayjs(event_date).format("YYYY-MM-DD"),
      eventName: event_name,
    };

    try {
      if (isEditMode && editRecord) {
        // Update holiday
        await axios.put(
          `${API_BASE_URL}/api/v1/events/${clinic_id}/${editRecord.event_id}`,
          formData,
          config
        );
        message.success("Holiday updated successfully!");
      } else {
        // Create new holiday
        await axios.post(
          `${API_BASE_URL}/api/v1/events/createEvent`,
          { ...formData, clinicId: clinic_id },
          config
        );
        message.success("Holiday added successfully!");
      }

      form.resetFields();
      setOpenHoliday(false);
      fetchHolidays();
    } catch (err) {
      message.error(err.response.data.data.errors[0]);
    } finally {
      setFormLoading(false);
      setIsEditMode(false); // Reset edit mode
      setEditRecord(null); // Clear edit record
    }
  };

  return (
    <div style={{ margin: "12px 0 0 0" }} className="holiday-main-div">
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          dataSource={Holidays}
          pagination={false}
          scroll={{ y: "55vh" }}
        />
      )}

      <Drawer open={openHoliday} closeIcon={false} destroyOnClose>
        <Spin size="large" spinning={formLoading}>
          <div style={{ height: "90vh" }} className="holiday-drawer-main-div">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className="h-100 d-flex flex-col jc-between"
            >
              <Row className="d-flex flex-col" style={{ gap: 20 }}>
                <Row className="d-flex jc-end">
                  <CloseOutlined
                    onClick={() => setOpenHoliday(false)}
                    style={{ cursor: "pointer", fontSize: 24 }}
                  />
                </Row>
                <Row>
                  <span className="add-holiday-header">
                    {isEditMode ? "Edit Holiday" : "Add Holiday"}
                  </span>
                </Row>
                <Row className="d-flex flex-col">
                  <span className="input-header">
                    Holiday Name <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="event_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the holiday name",
                      },
                    ]}
                  >
                    <Input className="input" placeholder="Holiday Name" />
                  </Form.Item>
                </Row>
                <Row className="d-flex flex-col">
                  <span className="input-header">
                    Date <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="event_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select the holiday date",
                      },
                    ]}
                  >
                    <DatePicker
                      className="input"
                      style={{ width: "100%" }}
                      placeholder="Select Date"
                      disabledDate={(current) =>
                        !isEditMode &&
                        current &&
                        current < dayjs().startOf("day")
                      }
                    />
                  </Form.Item>
                </Row>
              </Row>
              <Row className="d-flex jc-end">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {isEditMode ? "Update" : "Submit"}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Spin>
      </Drawer>

      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete"
        centered
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this Holiday?</p>
      </Modal>
    </div>
  );
};

export default Holidays;
