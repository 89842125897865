import { Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "30px" }} className="d-flex jc-center">
      <div style={{ height: "", width: "70vw", overflow: "auto" }}>
        <Row>
          <h1>TERMS & CONDITIONS</h1>
          <section>
            <h3>Last Updated: 12-11-2023</h3>
            <p>
              These Terms and Conditions ("Terms") govern your use of Ivory.ai
              ("the App"), provided by [Your Company Name], an Indian company.
              By using the App, you agree to these Terms. If you do not agree
              with these Terms, you are advised to not use the App.
            </p>
          </section>
          <section>
            <h2>1. Acceptance of Terms</h2>
            <p>
              1.1 By accessing or using the App, you confirm that you are of
              legal age to enter into a binding contract in your jurisdiction.
            </p>
            <div>
              <p>
                1.2 Ivory.ai is an online service offered by the Company. By
                accessing or using any website with an authorized link to the
                Website and/or the App, registering an account, or accessing or
                using any content, information, services, features, or resources
                available or enabled via the Website and/or the App
                (collectively, the "Services"), clicking on a button, or taking
                another action to signify your acceptance of this Agreement, you
              </p>
              <p>
                (a) agree to be bound by this Agreement and any future
                amendments and additions to this Agreement as published through
                the Services.
              </p>
              <p>
                (b) represent you are of legal age in your jurisdiction of
                residence to form a binding contract.
              </p>
              <p>
                (c) represent that you have the authority to enter into this
                Agreement personally and, if applicable, on behalf of any
                company, organization, or other legal entity on whose behalf you
                use the Services. If you are using the App on behalf of an
                organization or entity, you represent and warrant that you have
                the authority to bind that entity to these Terms, and "you"
                refers to both you and the entity.
              </p>
              <p>
                Except as otherwise provided herein, if you do not agree to be
                bound by this Agreement, you may not access or use the Services.
              </p>
            </div>
          </section>
          <section>
            <h2>2. App Purpose</h2>
            <p>
              2.1 The App provides dental information and self-diagnosis tools
              to assist users in assessing their dental health by way of (i)
              Patient Record Management System including managing contact
              details, treatment history, medical reports, progress notes and
              assessing health charts (ii) managing and processing the
              Information (defined below) of the patient in electronic format
              (iii) patient referrals through the mobile application (iv).
              Medical diagnosis of patient data such as X-rays by using AI
              Diagnosis tool and (v). patient appointment scheduling for meeting
              with the Client. It is not a substitute for professional dental
              advice, diagnosis, or treatment. The app shall refer you to
              consult with a qualified dental professional around your location
              for any dental health concerns.
            </p>
          </section>
          <section>
            <h3>3. User Responsibilities</h3>
            <p>
              3.1 You are solely responsible for the accuracy and completeness
              of any information you provide when using the App.
            </p>
            <p>
              3.2 You agree to use the App in accordance with these Terms and
              applicable laws and regulations. You will not violate any
              applicable law contract, intellectual property or other third
              party right or commit a tort, and you are solely responsible for
              your conduct while accessing or using the App. In connection with
              your use of the App and the Services, you will not
            </p>
            <ul>
              <li>
                Engage in any harassing, threatening, intimidating, predatory,
                or stalking conduct.
              </li>
              <li>
                Impersonate another person or log into an account which you are
                not authorized to access.
              </li>
              <li>
                Use or attempt to use another User's account without
                authorization from that User.
              </li>
              <li>
                Use the App in any manner that could interfere with, disrupt
                negatively affect or inhibit other users from fully enjoying use
                of the App and the Services or that could damage, disable, or
                impair the functioning of the App and the Services in any manner
                or network security. Interfere or attempt to interfere with
                service or any user, host or network, including by way of
                introducing a virus, overloading.
              </li>
              <li>
                Develop or use any third-party applications that Interact with
                the App or Services without our prior written consent, including
                any scripts designed to scrape or extract data from the App.
              </li>
            </ul>
          </section>
          <section>
            <h3>4. Privacy Policy</h3>
            <p>
              4.1 This Privacy Policy has last been updated on 12-11-2023. Your
              use of the App is also governed by our Privacy Policy. By using
              the App, you consent to the collection, use, and sharing of your
              information as described in the Privacy Policy.
            </p>
            <p>
              While using Our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Date of birth, Gender</li>
            </ul>
            <p>
              We also use information regarding your location to provide
              features of Our Service, to improve and customize Our Service. The
              information may be uploaded to the Company's servers and/or a
              Service Provider's server or it may be simply stored on Your
              device.
            </p>
            <ul>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>
                Usage Data such as IP address, the time and date of Your visit,
                the time spent on the platform, unique device identifiers and
                other diagnostic data. (If not required can be removed)
              </li>
            </ul>
            <p>The Personal Data shall be used for:</p>
            <ul>
              <li>To provide dental assessment and diagnostic services</li>
              <li>To manage your account on the app</li>
              <li>To manage your requests</li>
              <li>To contact you</li>
            </ul>
          </section>
          <section>
            <h3>5. No Medical Advice</h3>
            <p>
              5.1 The App does not provide medical or dental advice. The
              information provided through the App is for informational purposes
              only and is not a substitute for professional dental or medical
              advice, diagnosis, or treatment. Users are advised to use the
              dentist referral option on the app in case they need a
              professional dental diagnosis.
            </p>
          </section>
          <section>
            <h3>6. Limitation of Liability</h3>
            <p>
              6.1. Ivory.ai shall not be liable for any direct, indirect,
              incidental, special, consequential, or punitive damages, or any
              loss of profits, data, use, goodwill, or other intangible losses,
              resulting from your use of the App or reliance on the information
              provided by the App.
            </p>
          </section>
          <section>
            <h3>7. App Updates</h3>
            <p>
              7.1. Ivory.ai may release updates to the App from time to time.
              You are encouraged to use the latest version of the App for the
              most accurate information and functionality.
            </p>
          </section>
          <section>
            <h3>8. Termination</h3>
            <p>
              8.1. Ivory.ai may, at its sole discretion, terminate or suspend
              your access to the App at any time, with or without cause and
              without notice.
            </p>
          </section>
          <section>
            <h3>9. Changes to Terms</h3>
            <p>
              9.1. Ivory.ai reserves the right to modify or revise these Terms
              at any time. We will provide notice of any material changes. Your
              continued use of the App after the effective date of the updated
              Terms constitutes your acceptance of the changes.
            </p>
          </section>
          <section>
            <h3>10. Governing Law and Jurisdiction</h3>
            <p>
              10.1. These Terms shall be governed by and construed in accordance
              with the laws of India, without regard to its conflict of law
              principles. Any disputes arising under or in connection with these
              Terms shall be subject to the exclusive jurisdiction of the courts
              of Hyderabad.
            </p>
          </section>
          <section>
            <h3>
              11. Contact Information (If not required we can try to remove this
              part)
            </h3>
            <p>
              11.1. For questions or concerns regarding these Terms, please
              contact us at business@ivory.ai
            </p>
            <p>
              By accepting these Terms, you acknowledge that you have read,
              understood, and agree to be bound by them. These Terms are a
              legally binding agreement between you and Ivory.ai.
            </p>
          </section>
        </Row>
        {/* <Row style={{ padding: "0 0 20px 0", gap: 15 }}>
          <Button className="go-back-button" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Row> */}
      </div>
    </div>
  );
};

export default Terms;
