import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import backIcon from "../../../../assets/arrow_back.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../../env";

const InsuranceData = () => {
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");
  const [patientDetails, setPatientDetails] = useState([]);

  const { patientId, recordId } = useParams();

  const getInsuranceDetailsByPatientId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(
        `${API_BASE_URL}/api/v1/insurance/getAllInsuranceDetailsByMemberId?patientId=${patientId}&memberId=${recordId}`,
        config
      )
      .then((res) => {
        setPatientDetails(res.data.data[0]);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getInsuranceDetailsByPatientId();
  }, [recordId]);

  return (
    <div className="insurance-data-main-div w-100">
      <Row className="w-100" gutter={[24, 24]}>
        <Col>
          <img
            src={backIcon}
            alt="Back"
            onClick={() => navigate(-1)}
            style={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
          />
        </Col>
        <Col>
          <div className="mem-id-div">{patientDetails?.member_id}</div>
          <Row className="w-100" gutter={[]}>
            <Col>
              <div className="data-column">
                <span className="data-header">Patient Id</span>
                <span className="data">{patientDetails?.patient_id}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">Name</span>
                <span className="data">
                  {patientDetails?.first_name} {patientDetails?.last_name}
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">insurance_company_name</span>
                <span className="data">
                  {patientDetails?.insurance_company_name}
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">eligibility_type</span>
                <span className="data">{patientDetails?.eligibility_type}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">guarantor_id</span>
                <span className="data">{patientDetails?.guarantor_id}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default InsuranceData;
