import { Button, Col, Form, Input, message, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import silderimage from "../../assets/setpasswordimg.png";
import ivorylogo from "../../assets/ivorylogo.png";
import API_BASE_URL from "../../env";
import axios from "axios";
import {
  CheckCircleFilled,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import zxcvbn from "zxcvbn";

const SetPassword = () => {
  const [form] = Form.useForm();
  const [spinner, setSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    upperLowerCase: false,
    numberSymbol: false,
    commonWord: true,
    noWhiteSpace: true,
    isStrong: false,
  });

  const validatePassword = (value) => {
    const length = value.length >= 8;
    const upperLowerCase = /(?=.*[a-z])(?=.*[A-Z])/.test(value);
    const numberSymbol = /(?=.*\d)(?=.*[@#$!%*?&_])/.test(value);
    const noWhiteSpace = !/\s/.test(value);
    const zxcvbnResult = zxcvbn(value);
    const commonWord = zxcvbnResult.feedback.suggestions.length === 0;
    const isStrong = zxcvbnResult.score >= 3;

    setPasswordValidations({
      length,
      upperLowerCase,
      numberSymbol,
      commonWord,
      noWhiteSpace,
      isStrong,
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const repetitionRegex = /(.)\1{4,}/;
    if (repetitionRegex.test(value)) {
      message.error(
        "Password must not contain repeated characters or sequences."
      );
    } else {
      setPassword(value);
      validatePassword(value);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePasswords = (_, value) => {
    const { Username, ClinicName } = form.getFieldsValue([
      "Username",
      "ClinicName",
    ]);
    if (value.includes(Username) || value.includes(ClinicName)) {
      return Promise.reject(
        new Error(
          "Password must not contain username or parts of the clinic name"
        )
      );
    }
    return Promise.resolve();
  };

  // const [email, setEmail] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromUrl = queryParams.get("token");

  const handleSetPassword = (values) => {
    setSpinner(true);
    const payload = {
      password: values.password,
      confirmPassword: values.confirm,
      token: tokenFromUrl,
    };
    axios
      .put(`${API_BASE_URL}/api/v1/clinic/set-password`, payload)
      .then((res) => {
        message.success(res.data.data);
        form.resetFields();
        setSpinner(false);
        setIsModalOpen(true);
      })
      .catch((err) => {
        setSpinner(false);
        message.error(err.response.data.data.errors[0]);
      });
  };

  return (
    <>
      {tokenFromUrl ? (
        <>
          <div className="set-password-main-div">
            <Row className="h-100">
              {window.innerWidth > 1200 && (
                <Col
                  sm={15}
                  xl={15}
                  xxl={15}
                  className="d-flex jc-center ai-center flex-col slideshow-col"
                >
                  <img
                    alt=""
                    src={silderimage}
                    className="setpassword-silderimage"
                  />
                  <span className="slider-header-text">
                    AI-Powered Dental Assessments
                  </span>
                  <span className="slider-subheader-text">
                    Instantly analyze dental X-rays using advanced AI technology
                    to identify potential issues, recommend treatments, and
                    provide comprehensive reports.
                  </span>
                </Col>
              )}
              <Col
                sm={24}
                xl={9}
                xxl={9}
                className="d-flex flex-col jc-center set-password-form-container"
                style={{ padding: "0 90px 0 0" }}
              >
                <Row className="w-100 d-flex jc-center">
                  <img alt="" src={ivorylogo} className="ivory-logo" />
                </Row>
                <Spin
                  spinning={spinner}
                  indicator={<LoadingOutlined spin />}
                  size="large"
                >
                  <Form
                    className="w-100 d-flex jc-center"
                    onFinish={handleSetPassword}
                    form={form}
                  >
                    <Row className="set-password-form">
                      <Row className="w-100 d-flex jc-center">
                        <span className="set-password-text">
                          Set Your Password
                        </span>
                      </Row>
                      <Row className="w-100">
                        <span className="input-header-text">New Password</span>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Password",
                            },
                            { validator: validatePasswords },
                          ]}
                          hasFeedback
                          className="w-100"
                        >
                          <Input.Password
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onCut={(e) => e.preventDefault()}
                            data-testid="jest-input-password"
                            maxLength={31}
                            className="signup-input-fields"
                            onChange={handlePasswordChange}
                          />
                        </Form.Item>
                      </Row>
                      <Row className="w-100">
                        <div className="password-policy-div">
                          <ul>
                            <li
                              style={{
                                color: password
                                  ? passwordValidations.length
                                    ? "green"
                                    : "red"
                                  : "gray",
                              }}
                            >
                              At least 8 characters long
                            </li>
                            <li
                              style={{
                                color: password
                                  ? passwordValidations.upperLowerCase
                                    ? "green"
                                    : "red"
                                  : "gray",
                              }}
                            >
                              Include upper and lower case letters
                            </li>
                            <li
                              style={{
                                color: password
                                  ? passwordValidations.numberSymbol
                                    ? "green"
                                    : "red"
                                  : "gray",
                              }}
                            >
                              Use numbers and symbols
                            </li>
                            <li
                              style={{
                                color: password
                                  ? passwordValidations.commonWord
                                    ? "green"
                                    : "red"
                                  : "gray",
                              }}
                            >
                              Avoid common words, phrases
                            </li>
                            <li
                              style={{
                                color: password
                                  ? passwordValidations.isStrong
                                    ? "green"
                                    : "red"
                                  : "gray",
                              }}
                            >
                              Password is strong enough
                            </li>
                          </ul>
                        </div>
                      </Row>
                      <Row className="w-100">
                        <span className="input-header-text">
                          Confirm Password
                        </span>
                        <Form.Item
                          className="w-100"
                          name="confirm"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("The passwords do not match!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onCut={(e) => e.preventDefault()}
                            data-testid="jest-input-password-confirm"
                            maxLength={31}
                            className="signup-input-fields"
                            onChange={handleConfirmPasswordChange}
                          />
                        </Form.Item>
                      </Row>
                      <Row className="w-100 d-flex flex-col">
                        <Button
                          htmlType="submit"
                          disabled={
                            !Object.values(passwordValidations).every(Boolean)
                          }
                          className="password-btn"
                        >
                          Confirm
                        </Button>
                      </Row>
                    </Row>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </div>

          <Modal
            width={"651px"}
            open={isModalOpen}
            footer={false}
            centered
            closable={false}
          >
            <Row className="w-100 d-flex jc-end">
              <CloseOutlined
                style={{ color: "#EC7063", fontSize: 22 }}
                onClick={() => navigate("/")}
              />
            </Row>
            <Row className="w-100 d-flex jc-center">
              <CheckCircleFilled
                style={{
                  color: "#71BF8C",
                  fontSize: 123,
                  margin: "18px 0 0 0",
                }}
              />
            </Row>
            <Row className="w-100 d-flex jc-center">
              <span
                className="passowrd-text-one"
                style={{ margin: "30px 0 0 0" }}
              >
                Password Created Successfully!
              </span>
            </Row>
            <Row className="w-100 d-flex jc-center">
              <span
                className="passowrd-text-two"
                style={{ margin: "10px 0 0 0" }}
              >
                Your password has been set. You can now log in to access your
                account.
              </span>
            </Row>
            <Row className="w-100 d-flex jc-center">
              <Button
                style={{ margin: "40px 0 0 0" }}
                className="passowrd-btm"
                onClick={() => navigate("/")}
              >
                Go to Login
              </Button>
            </Row>
          </Modal>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
          }}
          className="d-flex jc-center ai-center"
        >
          <Spin
            indicator={<LoadingOutlined spin />}
            size="large"
            spinning={true}
          >
            <span>404 Page not found</span>
          </Spin>
        </div>
      )}
    </>
  );
};

export default SetPassword;
