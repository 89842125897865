import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Input, Space, Table, Button, AutoComplete, message, Spin } from "antd";
import axios from "axios";
import API_BASE_URL from "../../../../env";
import delete_icon from "../../../../assets/delete.png";
import edit_alt from "../../../../assets/edit.png";

const TreatmentCosts = forwardRef((props, ref) => {
  const [data, setData] = useState([]); // Table data
  const [editingKey, setEditingKey] = useState(null); // Track the currently editing row
  const [editingRow, setEditingRow] = useState({}); // Store data of the editing row
  const [isNewRow, setIsNewRow] = useState(false); // Track if the row is newly added
  const [codeOptions, setCodeOptions] = useState([]); // Options for AutoComplete
  const [apiData, setApiData] = useState([]); // Raw API data

  const [spinner, setSpinner] = useState(false);

  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  // Add a new row to the table
  const addTreatment = () => {
    const newKey = `new-${Date.now()}`;
    setData([
      ...data,
      {
        key: newKey,
        treatment: "",
        treatment_code: "",
        cost: "",
        duration: "",
      },
    ]);
    setEditingKey(newKey); // Set the new row to be editable
    setEditingRow({ key: newKey }); // Initialize the editingRow state
    setIsNewRow(true); // Mark this row as newly added
  };

  // Use `useImperativeHandle` to expose methods
  useImperativeHandle(ref, () => ({
    addTreatment,
  }));

  // Save the changes for the editing row
  const handleSave = async () => {
    if (
      !editingRow.treatment ||
      !editingRow.cost ||
      !editingRow.duration ||
      !editingRow.treatment_code
    ) {
      message.error("Please fill in all required fields.");
      return;
    }

    const payload = {
      clinic_id: parseInt(clinic_id, 10),
      treatment_name: editingRow.treatment,
      cost: parseFloat(editingRow.cost) || 0,
      duration: parseInt(editingRow.duration, 10) || 0,
      cdt_service_type: editingRow.cdt_service_type || "ServiceTypePlaceholder",
      cdt_service_sub_type:
        editingRow.cdt_service_sub_type || "ServiceSubTypePlaceholder",
      code: editingRow.treatment_code,
      code_name:
        apiData.find((item) => item.Code === editingRow.treatment_code)
          ?.Code_name ||
        editingRow.code_name ||
        "",
      code_description:
        apiData.find((item) => item.Code === editingRow.treatment_code)
          ?.Code_description ||
        editingRow.code_description ||
        "",
    };
    setSpinner(true);

    if (editingRow && typeof editingRow.key === "string") {
      // POST API call for new row
      await axios
        .post(`${API_BASE_URL}/api/v1/treatments/add-treatment`, payload, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((res) => {
          setEditingKey(null);
          setEditingRow({});
          setIsNewRow(false);
          // setSpinner(flas);
          fetchTreatments();
          message.success("Treatment saved successfully.");
        })
        .catch((err) => {
          message.error("Failed to save treatment. Please try again.");
          setSpinner(false);
        });
    } else {
      // PUT API call for existing row
      await axios
        .put(
          `${API_BASE_URL}/api/v1/treatments/update-treatment/{treatmentId}?treatmentId=${editingRow.key}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        )
        .then((res) => {
          setEditingKey(null);
          setEditingRow({});
          setIsNewRow(false);
          fetchTreatments();
          message.success("Treatment updated successfully.");
        })
        .catch((err) => {
          message.error("Failed to save treatment. Please try again.");
          setSpinner(false);
        });
    }
  };

  const handleCancel = () => {
    if (isNewRow) {
      // If the row is new, remove it
      setData(data.filter((item) => item.key !== editingKey));
    }
    setEditingKey(null); // Reset editingKey
    setEditingRow({}); // Clear editingRow
    setIsNewRow(false); // Reset new row flag
    setSpinner(false);
    fetchTreatments();
  };

  // Delete a row
  const handleDelete = async (key) => {
    setSpinner(true);
    console.log(key);
    try {
      await axios.delete(
        `${API_BASE_URL}/api/v1/treatments/{treatmentId}?treatmentId=${key}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setData(data.filter((item) => item.key !== key));
      fetchTreatments();
      message.success("Treatment deleted successfully.");
    } catch (error) {
      message.error("Failed to delete treatment. Please try again.");
    }
  };

  // Handle input changes for the editing row
  const handleInputChange = (field, value) => {
    setEditingRow({
      ...editingRow,
      [field]: value,
    });
  };

  // Start editing a row
  const handleEdit = (key) => {
    setEditingKey(key);
    const rowToEdit = data.find((item) => item.key === key);
    setEditingRow({ ...rowToEdit });
    console.log({ ...rowToEdit });
    setIsNewRow(false); // Editing an existing row
  };

  // Fetch treatment codes for AutoComplete
  const fetchTreatmentCodes = async () => {
    try {
      const res = await axios.get(
        `https://m67vnbdw5v76oionugzhlmkeri0tguba.lambda-url.ap-south-1.on.aws/data`
      );
      const rawData = res.data.Root;
      const processedData = rawData.map((item) => {
        const rowObj = Object.fromEntries(
          item.Row.map((entry) => Object.entries(entry)[0])
        );
        return rowObj;
      });
      setApiData(processedData);
    } catch (err) {
      message.error("Failed to fetch API data:", err);
    }
  };

  // Fetch existing treatments
  const fetchTreatments = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/treatments/fetch-all-treatments/{clinicId}?clinicId=${clinic_id}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      const treatments = response.data.data.map((item) => ({
        key: item.treatment_id,
        treatment: item.treatment_name,
        treatment_code: item.code,
        cost: item.cost,
        duration: item.duration,
        cdt_service_type: item.cdt_service_type,
        cdt_service_sub_type: item.cdt_service_sub_type,
        code_name: item.code_name,
        code_description: item.code_description,
      }));
      setData(treatments);
      setSpinner(false);
    } catch (err) {
      message.error("Failed to fetch treatments:", err);
      setSpinner(false);
    }
  };

  useEffect(() => {
    fetchTreatmentCodes();
    fetchTreatments();
  }, [clinic_id, Token]);

  // Filter and populate treatment codes for AutoComplete
  const handleCodeSearch = (value) => {
    const filteredOptions = apiData
      .filter((item) => item.Code?.toLowerCase().includes(value.toLowerCase()))
      .map((item) => ({
        value: item.Code,
        label: `${item.Code} - ${item.Code_name}`,
      }));
    setCodeOptions(filteredOptions);
  };

  const handleCodeSelect = (value) => {
    handleInputChange("treatment_code", value);
  };

  // Table columns
  const columns = [
    {
      title: <span className="table-header-names">TREATMENT</span>,
      dataIndex: "treatment",
      key: "treatment",
      render: (text, record) =>
        editingKey === record.key ? (
          <Input
            className="table-content-input"
            value={editingRow.treatment || ""}
            onChange={(e) => handleInputChange("treatment", e.target.value)}
          />
        ) : (
          <span className="table-col-names">{text}</span>
        ),
      width: "24%",
    },
    {
      title: <span className="table-header-names">Treatment Code</span>,
      dataIndex: "treatment_code",
      key: "treatment_code",
      render: (text, record) =>
        editingKey === record.key ? (
          <AutoComplete
            bordered={false}
            className="table-content-input"
            options={codeOptions}
            onSearch={(value) => {
              if (value.length > 1) handleCodeSearch(value);
              handleInputChange("treatment_code", value);
            }}
            onSelect={handleCodeSelect}
            value={editingRow.treatment_code || ""}
            placeholder="Search Treatment Code"
          />
        ) : (
          <span className="table-col-names">{text}</span>
        ),
      width: "24%",
    },
    {
      title: <span className="table-header-names">Cost ($)</span>,
      dataIndex: "cost",
      key: "cost",
      render: (text, record) =>
        editingKey === record.key ? (
          <Input
            className="table-content-input"
            value={editingRow.cost || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value)) handleInputChange("cost", value);
            }}
          />
        ) : (
          <span className="table-col-names">{text}</span>
        ),
      width: "24%",
    },
    {
      title: <span className="table-header-names">Duration (min)</span>,
      dataIndex: "duration",
      key: "duration",
      render: (text, record) =>
        editingKey === record.key ? (
          <Input
            className="table-content-input"
            value={editingRow.duration || ""}
            onChange={(e) => handleInputChange("duration", e.target.value)}
          />
        ) : (
          <span className="table-col-names">{text}</span>
        ),
      width: "24%",
    },
    {
      title: <span className="table-header-names">Actions</span>,
      key: "actions",
      render: (_, record) =>
        editingKey === record.key ? (
          <Space>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Space>
        ) : (
          <Space>
            <img
              onClick={() => handleEdit(record.key)}
              src={edit_alt}
              alt=""
              style={{
                cursor: "pointer",
                width: 24,
              }}
            />
            <img
              onClick={() => handleDelete(record.key)}
              src={delete_icon}
              alt=""
              style={{
                cursor: "pointer",
                width: 24,
              }}
            />
          </Space>
        ),
      width: "4%",
    },
  ];

  return (
    <div>
      <Spin spinning={spinner && true}>
        <Table
          dataSource={data}
          columns={columns}
          rowClassName={(record) =>
            record.key === editingKey ? "editable-row" : ""
          }
        />
      </Spin>
    </div>
  );
});

export default TreatmentCosts;
