import { Checkbox, Col, Row, Select, Form, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import API_BASE_URL from "../../../../env";

const DateTime = () => {
  const [businessForm] = Form.useForm();
  const [exceptionForm] = Form.useForm();
  const [existingBusinessHours, setExistingBusinessHours] = useState(null);
  const [existingExceptionHours, setExistingExceptionHours] = useState(null);

  const [isBusinessFormChanged, setIsBusinessFormChanged] = useState(false);
  const [isExceptionFormChanged, setIsExceptionFormChanged] = useState(false);

  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  const hours = Array.from({ length: 12 }, (_, i) => `0${i + 1}`.slice(-2));
  const minutes = ["00", "15", "30", "45"];
  const ampmOptions = ["AM", "PM"];

  // Fetch business hours for the clinic
  const fetchBusinessDays = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    try {
      const res = await axios.get(
        `${API_BASE_URL}/api/v1/clinic/get-business-hours?clinicId=${clinic_id}`,
        config
      );
      const data = res.data.data;
      if (data && data.length > 0) {
        setExistingBusinessHours(data);
        populateBusinessExistingData(data);
      } else {
        setExistingBusinessHours(null);
      }
    } catch (err) {
      message.error(err.response?.data?.data?.errors || "Failed to fetch data");
    }
  };

  const fetchExceptionDays = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    try {
      const res = await axios.get(
        `${API_BASE_URL}/api/v1/clinic/get-exception-hours?clinicId=${clinic_id}`,
        config
      );
      const data = res.data.data;
      if (data && data.length > 0) {
        setExistingExceptionHours(data);
        populateExceptionExistingData(data);
      } else {
        setExistingExceptionHours(null);
      }
    } catch (err) {
      message.error(err.response?.data?.data?.errors || "Failed to fetch data");
    }
  };

  const convertToAMPMFormat = (time) => {
    if (!time) return { hour: null, minute: null, period: null };
    const [hour, minute] = time.split(":").map(Number);
    const period = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
    return {
      hour: String(adjustedHour).padStart(2, "0"),
      minute: String(minute).padStart(2, "0"),
      period,
    };
  };

  const populateBusinessExistingData = (data) => {
    if (!data || data.length === 0) return;

    const startTimes = convertToAMPMFormat(data[0]?.business_start_time);
    const endTimes = convertToAMPMFormat(data[0]?.business_end_time);

    const initialValues = {
      businessDays: data.map((item) => item.business_days),
      businessStartHour: startTimes.hour,
      businessStartMinute: startTimes.minute,
      businessStartAMPM: startTimes.period,
      businessEndHour: endTimes.hour,
      businessEndMinute: endTimes.minute,
      businessEndAMPM: endTimes.period,
    };
    businessForm.setFieldsValue(initialValues);
  };

  // ExceptionHours
  const populateExceptionExistingData = (data) => {
    if (!data || data.length === 0) return;

    const startTimes = convertToAMPMFormat(data[0]?.exception_start_time);
    const endTimes = convertToAMPMFormat(data[0]?.exception_end_time);

    const initialValues = {
      exceptionDays: data.map((item) => item.exception_days),
      exceptionStartHour: startTimes.hour,
      exceptionStartMinute: startTimes.minute,
      exceptionStartAMPM: startTimes.period,
      exceptionEndHour: endTimes.hour,
      exceptionEndMinute: endTimes.minute,
      exceptionEndAMPM: endTimes.period,
    };
    exceptionForm.setFieldsValue(initialValues);
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:00`;
  };

  const calculateRemainingDays = (selectedDays) => {
    const allDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const remainingDays = allDays.filter((day) => !selectedDays.includes(day));
    return remainingDays.join(",");
  };

  const validateTimeRange = (
    startHour,
    startMinute,
    startPeriod,
    endHour,
    endMinute,
    endPeriod
  ) => {
    const startTime = new Date();
    const endTime = new Date();

    startTime.setHours(
      startPeriod === "PM"
        ? parseInt(startHour, 10) + (startHour === "12" ? 0 : 12)
        : parseInt(startHour, 10) % 12,
      parseInt(startMinute, 10),
      0
    );

    endTime.setHours(
      endPeriod === "PM"
        ? parseInt(endHour, 10) + (endHour === "12" ? 0 : 12)
        : parseInt(endHour, 10) % 12,
      parseInt(endMinute, 10),
      0
    );

    return startTime < endTime;
  };

  // Handle auto-save when values change
  const handleSaveBusinessDays = async () => {
    const values = businessForm.getFieldsValue();
    const uniqueDays = [...new Set(values.businessDays)];

    // Validate time range
    const isValidTimeRange = validateTimeRange(
      values.businessStartHour,
      values.businessStartMinute,
      values.businessStartAMPM,
      values.businessEndHour,
      values.businessEndMinute,
      values.businessEndAMPM
    );

    if (!isValidTimeRange) {
      message.error("Start time should be less than end time");
      return; // Exit without hitting the API
    }

    const remainingDays = calculateRemainingDays(uniqueDays);

    const formData = {
      clinic_id: clinic_id,
      business_days: uniqueDays.join(","),
      remaining_days: remainingDays,
      business_start_time: convertTo24HourFormat(
        `${values.businessStartHour}:${values.businessStartMinute} ${values.businessStartAMPM}`
      ),
      business_end_time: convertTo24HourFormat(
        `${values.businessEndHour}:${values.businessEndMinute} ${values.businessEndAMPM}`
      ),
      time_zone: "UTC",
    };

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    try {
      if (existingBusinessHours && existingBusinessHours.length > 0) {
        // Update existing business hours via PUT
        const endpoint = `${API_BASE_URL}/api/v1/clinic/updateClinicBusinessHours/{clinicId}?clinicId=${clinic_id}`;
        await axios.put(endpoint, formData, config);
        message.success("Successfully updated business hours!");
        setIsBusinessFormChanged(false);
        fetchBusinessDays();
      } else {
        // Create new business hours via POST if no data exists
        const endpoint = `${API_BASE_URL}/api/v1/clinic/createBusinessHours`;
        await axios.post(endpoint, formData, config);
        message.success("Successfully created business hours!");
        setIsBusinessFormChanged(false);
        fetchBusinessDays();
      }
    } catch (error) {
      message.error(
        error.response?.data?.data?.errors || "Failed to save data"
      );
    }
  };

  const handleSaveExceptionDays = async () => {
    const values = exceptionForm.getFieldsValue();
    const uniqueDays = [...new Set(values.exceptionDays)];

    // Validate time range
    const isValidTimeRange = validateTimeRange(
      values.exceptionStartHour,
      values.exceptionStartMinute,
      values.exceptionStartAMPM,
      values.exceptionEndHour,
      values.exceptionEndMinute,
      values.exceptionEndAMPM
    );

    if (!isValidTimeRange) {
      message.error("Start time should be less than end time");
      return; // Exit without hitting the API
    }

    const remainingDays = calculateRemainingDays(uniqueDays);

    const formData = {
      clinic_id: clinic_id,
      exception_days: uniqueDays.join(","),
      remaining_days: remainingDays,
      exception_start_time: convertTo24HourFormat(
        `${values.exceptionStartHour}:${values.exceptionStartMinute} ${values.exceptionStartAMPM}`
      ),
      exception_end_time: convertTo24HourFormat(
        `${values.exceptionEndHour}:${values.exceptionEndMinute} ${values.exceptionEndAMPM}`
      ),
      time_zone: "UTC",
    };

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    try {
      if (existingExceptionHours && existingExceptionHours.length > 0) {
        // Update existing business hours via PUT
        const endpoint = `${API_BASE_URL}/api/v1/clinic/updateExceptionHours/{clinicId}?clinicId=${clinic_id}`;
        await axios.put(endpoint, formData, config);
        message.success("Successfully updated exception hours!");
        setIsExceptionFormChanged(false);
        fetchExceptionDays();
      } else {
        // Create new business hours via POST if no data exists
        const endpoint = `${API_BASE_URL}/api/v1/clinic/createExceptionHours`;
        await axios.post(endpoint, formData, config);
        message.success("Successfully created exception hours!");
        setIsExceptionFormChanged(false);
        fetchExceptionDays();
      }
    } catch (error) {
      message.error(
        error.response?.data?.data?.errors || "Failed to save data"
      );
    }
  };

  const handleCancelBusinessChanges = () => {
    businessForm.resetFields();
    setIsBusinessFormChanged(false);
    fetchBusinessDays();
  };

  const handleCancelExceptionChanges = () => {
    exceptionForm.resetFields();
    setIsExceptionFormChanged(false);
    fetchExceptionDays();
  };

  const handleBusinessFormChange = () => {
    setIsBusinessFormChanged(true);
  };

  const handleExceptionFormChange = () => {
    setIsExceptionFormChanged(true);
  };

  useEffect(() => {
    fetchBusinessDays();
  }, [clinic_id, Token]);

  useEffect(() => {
    fetchExceptionDays();
  }, [clinic_id, Token]);

  return (
    <Row gutter={[0, 24]} className="date-time-tab-main-div">
      <Form
        form={businessForm}
        onFinish={handleSaveBusinessDays}
        layout="vertical"
        onValuesChange={handleBusinessFormChange} // Trigger auto-save on form value change
      >
        <Row gutter={20} style={{ display: "flex", gap: 20 }}>
          {/* Business days form elements */}
          <Col span={24}>
            <span className="language-date-time-text">Business Days</span>
            <Row className="d-flex ai-center" style={{ padding: "20px 0 0 0" }}>
              <Col span={7}>
                <span className="input-header">
                  Select working days <span style={{ color: "#F00" }}>*</span>
                </span>
              </Col>
              <Col>
                <Form.Item
                  className="d-flex flex-col"
                  name="businessDays"
                  rules={[
                    { required: true, message: "Please select working days" },
                  ]}
                >
                  <Checkbox.Group>
                    <Checkbox value="Sunday">Sunday</Checkbox>
                    <Checkbox value="Monday">Monday</Checkbox>
                    <Checkbox value="Tuesday">Tuesday</Checkbox>
                    <Checkbox value="Wednesday">Wednesday</Checkbox>
                    <Checkbox value="Thursday">Thursday</Checkbox>
                    <Checkbox value="Friday">Friday</Checkbox>
                    <Checkbox value="Saturday">Saturday</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Business hours form elements */}
          <Col span={24}>
            <span className="language-date-time-text">Business Hours</span>
            <Row style={{ gap: 84, padding: "0px 0 0 0" }}>
              <Col>
                <span className="input-header">
                  Starts At <span style={{ color: "#F00" }}>*</span>
                </span>
                <Row style={{ gap: 8 }}>
                  {/* Start time fields */}
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessStartHour"
                    rules={[{ required: true, message: "Hour is required" }]}
                  >
                    <Select
                      bordered={false}
                      placeholder="hh"
                      className="time-select"
                    >
                      {hours.map((hour) => (
                        <Select.Option key={hour} value={hour}>
                          {hour}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessStartMinute"
                    rules={[
                      { required: true, message: "Minutes are required" },
                    ]}
                  >
                    <Select
                      bordered={false}
                      placeholder="mm"
                      className="time-select"
                    >
                      {minutes.map((minute) => (
                        <Select.Option key={minute} value={minute}>
                          {minute}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessStartAMPM"
                    rules={[{ required: true, message: "AM/PM is required" }]}
                  >
                    <Select
                      bordered={false}
                      placeholder="AM"
                      className="time-select"
                    >
                      {ampmOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
              </Col>

              <Col>
                <span className="input-header">
                  Ends At <span style={{ color: "#F00" }}>*</span>
                </span>
                <Row style={{ gap: 8 }}>
                  {/* End time fields */}
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessEndHour"
                    rules={[{ required: true, message: "Hour is required" }]}
                  >
                    <Select
                      bordered={false}
                      placeholder="hh"
                      className="time-select"
                    >
                      {hours.map((hour) => (
                        <Select.Option key={hour} value={hour}>
                          {hour}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessEndMinute"
                    rules={[
                      { required: true, message: "Minutes are required" },
                    ]}
                  >
                    <Select
                      bordered={false}
                      placeholder="mm"
                      className="time-select"
                    >
                      {minutes.map((minute) => (
                        <Select.Option key={minute} value={minute}>
                          {minute}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="d-flex flex-col"
                    name="businessEndAMPM"
                    rules={[{ required: true, message: "AM/PM is required" }]}
                  >
                    <Select
                      bordered={false}
                      placeholder="AM"
                      className="time-select"
                    >
                      {ampmOptions.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex jc-end ai-center">
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="save-btn"
              disabled={!isBusinessFormChanged}
            >
              Save Business Hours
            </Button>
          </Form.Item>
          {isBusinessFormChanged && (
            <Button
              className="cancel-btn"
              type="danger"
              style={{ marginLeft: "10px" }}
              onClick={handleCancelBusinessChanges}
            >
              Cancel
            </Button>
          )}
        </Row>
      </Form>
      {existingBusinessHours && existingBusinessHours.length > 0 && (
        <Form
          form={exceptionForm}
          layout="vertical"
          onValuesChange={handleExceptionFormChange} // Trigger auto-save on form value change
          onFinish={handleSaveExceptionDays}
        >
          <Row gutter={20} style={{ display: "flex", gap: 20 }}>
            <Col span={24}>
              <span className="language-date-time-text">
                Exception Days & Time
              </span>
              <Row
                className="d-flex ai-center"
                style={{ padding: "20px 0 0 0" }}
              >
                <Col span={7}>
                  <span className="input-header">
                    Select working days <span style={{ color: "#F00" }}>*</span>
                  </span>
                </Col>
                <Col>
                  <Form.Item
                    className="d-flex flex-col"
                    name="exceptionDays"
                    rules={[
                      { required: true, message: "Please select working days" },
                    ]}
                  >
                    <Checkbox.Group>
                      <Checkbox value="Sunday">Sunday</Checkbox>
                      <Checkbox value="Monday">Monday</Checkbox>
                      <Checkbox value="Tuesday">Tuesday</Checkbox>
                      <Checkbox value="Wednesday">Wednesday</Checkbox>
                      <Checkbox value="Thursday">Thursday</Checkbox>
                      <Checkbox value="Friday">Friday</Checkbox>
                      <Checkbox value="Saturday">Saturday</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              {/* <span className="language-date-time-text">Business Hours</span> */}
              <Row style={{ gap: 84, padding: "0px 0 0 0" }}>
                <Col>
                  <span className="input-header">
                    Starts At <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Row style={{ gap: 8 }}>
                    {/* Start time fields */}
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionStartHour"
                      rules={[{ required: true, message: "Hour is required" }]}
                    >
                      <Select
                        bordered={false}
                        placeholder="hh"
                        className="time-select"
                      >
                        {hours.map((hour) => (
                          <Select.Option key={hour} value={hour}>
                            {hour}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionStartMinute"
                      rules={[
                        { required: true, message: "Minutes are required" },
                      ]}
                    >
                      <Select
                        bordered={false}
                        placeholder="mm"
                        className="time-select"
                      >
                        {minutes.map((minute) => (
                          <Select.Option key={minute} value={minute}>
                            {minute}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionStartAMPM"
                      rules={[{ required: true, message: "AM/PM is required" }]}
                    >
                      <Select
                        bordered={false}
                        placeholder="AM"
                        className="time-select"
                      >
                        {ampmOptions.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Row>
                </Col>

                <Col>
                  <span className="input-header">
                    Ends At <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Row style={{ gap: 8 }}>
                    {/* End time fields */}
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionEndHour"
                      rules={[{ required: true, message: "Hour is required" }]}
                    >
                      <Select
                        bordered={false}
                        placeholder="hh"
                        className="time-select"
                      >
                        {hours.map((hour) => (
                          <Select.Option key={hour} value={hour}>
                            {hour}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionEndMinute"
                      rules={[
                        { required: true, message: "Minutes are required" },
                      ]}
                    >
                      <Select
                        bordered={false}
                        placeholder="mm"
                        className="time-select"
                      >
                        {minutes.map((minute) => (
                          <Select.Option key={minute} value={minute}>
                            {minute}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="d-flex flex-col"
                      name="exceptionEndAMPM"
                      rules={[{ required: true, message: "AM/PM is required" }]}
                    >
                      <Select
                        bordered={false}
                        placeholder="AM"
                        className="time-select"
                      >
                        {ampmOptions.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="d-flex jc-end ai-center">
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className="save-btn"
                disabled={!isExceptionFormChanged}
              >
                Save Exception Hours
              </Button>
            </Form.Item>
            {isExceptionFormChanged && (
              <Button
                type="danger"
                className="cancel-btn"
                style={{ marginLeft: "10px" }}
                onClick={handleCancelExceptionChanges}
              >
                Cancel
              </Button>
            )}
          </Row>
        </Form>
      )}
    </Row>
  );
};

export default DateTime;
