import { Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import chevron_right from "../../../assets/chevron_right.png";
import ClinicProfile from "./tabs/ClinicProfile";
import Holidays from "./tabs/Holidays";
import DateTime from "./tabs/DateTime";
import Documents from "./tabs/Documents";
import TreatmentCosts from "./tabs/TreatmentCosts";
import APISync from "./tabs/APISync";
import Integrations from "./tabs/Integrations";

const General = ({ settabExtraContent, tabExtraContent, forwardedRef }) => {
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (key) => {
    setActiveKey(key);
    settabExtraContent({ tab: tabExtraContent.tab, subTab: key });
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Clinic Profile</span>
          </Col>
          {activeKey === "1" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <ClinicProfile />,
    },
    {
      key: "2",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Documents</span>
          </Col>
          {activeKey === "2" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <Documents />,
    },
    {
      key: "3",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Treatment Costs</span>
          </Col>
          {activeKey === "3" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <TreatmentCosts ref={forwardedRef} />,
    },
    {
      key: "4",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Date & Time</span>
          </Col>
          {activeKey === "4" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <DateTime />,
    },
    {
      key: "5",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Holidays</span>
          </Col>
          {activeKey === "5" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <Holidays />,
    },
    {
      key: "6",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">API Sync</span>
          </Col>
          {activeKey === "6" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <APISync />,
    },
    {
      key: "7",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Integrations</span>
          </Col>
          {activeKey === "7" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <Integrations />,
    },
  ];

  return (
    <div className="general-tab-main-div">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={activeKey}
        items={items}
        onChange={onChange}
        type="card"
      />
    </div>
  );
};

export default General;
