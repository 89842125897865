import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import API_BASE_URL from "../env";
import { message } from "antd";

const UserContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openHoliday, setOpenHoliday] = useState(false);

  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [ProData, setProData] = useState();

  const handleGetClinicProPic = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
      .then((res) => {
        const data = res.data.data;
        setProData(data);
      })
      .catch((err) => {
        message.error(
          err.response.data.data.errors || "Failed to load clinic details"
        );
      });
  };

  useEffect(() => {
    handleGetClinicProPic();
  }, [clinic_id]);

  return (
    <UserContext.Provider
      value={{
        spinner,
        setSpinner,
        open,
        showDrawer,
        onClose,
        setOpenHoliday,
        openHoliday,
        loading,
        setLoading,
        handleGetClinicProPic,
        ProData,
        setProData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserAuth = () => {
  return useContext(UserContext);
};
