import { Button, Col, DatePicker, Form, Input, message, Row, Select, Spin, TimePicker } from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import copyIcon from "../../../../../../assets/content_copy.png";
import emailIcon from "../../../../../../assets/email.png";
import phoneIcon from "../../../../../../assets/phone_iphone.png";
import moreIcon from "../../../../../../assets/expand_more.png";
import calendarIcon from "../../../../../../assets/calendar.png";
import '../../../../staffOffCanvas/StaffOffCanvas.css';
import TextArea from "antd/es/input/TextArea";
import CellWithOverflowTooltip from "../../../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../../../../env";

const ReviewOffcanvas = ({ show, setShow, mode, data }) => {
    const Token = localStorage.getItem('Token');
    const { staffId } = useParams();
    const [addStaffForm] = Form.useForm();
    const [spinner, setSpinner] = useState(false);
    const [patientData, setPatientData] = useState();
    const reviewOptions = [
        { value: "Initial Consultation", label: "Initial Consultation" },
        { value: "Follow-up Consultation", label: "Follow-up Consultation" },
        { value: "Post-Treatment Checkup", label: "Post-Treatment Checkup" },
        { value: "Routine Checkup", label: "Routine Checkup" },
    ];
    const medicalOptions = [
        { value: "Teeth Cleaning (Prophylaxis)", label: "Teeth Cleaning (Prophylaxis)" },
        { value: "Dental X-Ray", label: "Dental X-Ray" },
        { value: "Cavity Detection", label: "Cavity Detection" },
        { value: "Tooth Sensitivity Test", label: "Tooth Sensitivity Test" },
        { value: "Gum Examination", label: "Gum Examination" },
    ]

    const getInitials = (name) => {
        const nameParts = name.split(" ");
        const initials =
            nameParts[0][0].toUpperCase() + (nameParts[1]?.[0]?.toUpperCase() || "");
        return initials;
    };

    useEffect(() => {
        setPatientData(data[0]);
    }, [data])

    const handleClose = () => {
        setShow(false);
        addStaffForm.resetFields();
    };

    const copyToClipboard = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => {
                message.success("Email copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy email!");
            });
    };

    const handleStaffForm = async (values) => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }

        const fields = {
            practice_id: "2",
            provider_id: staffId,
            patient_id: patientData?.patientId,
            review_type: values.review_type,
            medical_examination: values.medical_examination,
            review_details: values.review_details,
            suggested_treatment: values.suggested_treatment,
            next_review_on: new Date(values.next_review_on).toLocaleDateString('en-CA'),
            additional_notes: values.additional_notes
        }

        axios
            .post(`${API_BASE_URL}/api/v1/providers/addPatientReview`, fields, config)
            .then((res) => {
                message.success('Review added successfully.');
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                message.error(err.response.data.data.errors);
            })

        // setSpinner(true);
    }

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" className='off-canvas'>

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Review</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className="review-offcanvas">
                <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'}>
                    <Form
                        form={addStaffForm}
                        onFinish={handleStaffForm}
                        className="offcanvas-form"
                    >
                        <div className="fields-container">
                            <Row
                                className="w-100 d-flex ai-end patients-details-container"
                            >
                                <Col>
                                    <div className="patient-initials">
                                        {getInitials(`${patientData?.firstname} ${patientData?.lastname}`)}
                                    </div>
                                </Col>
                                <Col className='details-section'>
                                    <Row className='details-row'>
                                        <Col className='details-column'>
                                            <span className='name'>{`${patientData?.firstname} ${patientData?.lastname}`}</span>
                                        </Col>
                                        <Col className='details-column'>
                                            <span className='details-header'>age & gender</span>
                                            <span
                                                className="age">{new Date().getFullYear() - new Date(patientData?.birthdate).getFullYear()} Years
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='details-row'>
                                        <Col className='details-column'>
                                            <span className='details-header'>contact</span>
                                            <div className='contact-info'>
                                                <span className="phone">
                                                    <img src={phoneIcon} alt='Phone' className='phone-icon' />
                                                    {patientData?.cell}
                                                </span>
                                                <span>
                                                    <img src={emailIcon} alt='Email' className='email-icon' />
                                                    <CellWithOverflowTooltip value={patientData?.email} />
                                                    <img src={copyIcon} alt='Copy' className='copy-icon' onClick={() => copyToClipboard(patientData?.email)} />
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className='details-column'>
                                            <span className="details-header">Last VISIT & Treatment</span>
                                            <span>
                                                09 Sep 2024 10:00 AM
                                            </span>
                                            <span>Dental checkup</span>
                                        </Col>
                                    </Row>
                                    {/* <Row className='details-row'>
                                        <Col className='details-column'>
                                            <span className='details-header'>Emergency Contact</span>
                                            <div className='contact-info'>
                                                <span className="phone">
                                                    <img src={phoneIcon} alt='Phone' className='phone-icon' />
                                                    +1 802 345-5556
                                                </span>
                                                <span className="ec-name">
                                                    Lucas P
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className='details-column'>
                                            <span className="details-header">dental history</span>
                                            <span>
                                                Dental Pain/Discomfort
                                            </span>
                                            <span>Orthodontics</span>
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            <Row className='form-row'>Review details</Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Review type <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="review_type" rules={[{ required: false }]}>
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                            options={reviewOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">Suggested treatment
                                    </span>
                                    <Form.Item name="suggested_treatment" rules={[{ required: false }]}>
                                        <Input
                                            className="form-input"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Medical examination
                                    </span>
                                    <Form.Item
                                        name="medical_examination"
                                    >
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                            options={medicalOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Next review on
                                    </span>
                                    <Form.Item
                                        name="next_review_on"

                                    >
                                        <DatePicker
                                            className="form-input"
                                            suffixIcon={<img src={calendarIcon} className="calendar-icon" />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Review details <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="review_details"
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea className="form-text-area" />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Additional notes
                                    </span>
                                    <Form.Item
                                        name="additional_notes"

                                    >
                                        <TextArea className="form-text-area" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <div className="buttons-container">
                            <Button className="cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'add'}>
                                Save
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'edit'}>
                                Add
                            </Button>
                        </div>

                    </Form>

                </Spin>
            </Offcanvas.Body>
        </Offcanvas >
    )
}

export default ReviewOffcanvas;