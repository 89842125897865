// // export const GOOGLE_API_KEY = "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";
export const GOOGLE_API_KEY = "6LfaADEqAAAAAObbgbJpS_9KQRHDgVSF-8S6zWaB"; // client side
// export const GOOGLE_API_KEY = "6LfaADEqAAAAAGHvkoJEjhuiCH0kyhCldZox6gCm"; // server side
// // export const GOOGLE_API_KEY = "AIzaSyBb13PkJrA1BsJjAwt6CNYBeLwvGHQ9NOY";

// export const S3_BUCKET = "ivorys3163752-dev";
export const S3_BUCKET = "ivoryaiusdev-test23aug2024";
export const REGION = "us-east-2";
export const ACCESS_KEY = "AKIAYOMTNCKI32QHIKGA";
export const SECRET_ACCESS_KEY = "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";
