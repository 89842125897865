import { Tooltip } from "antd";
import { useState } from "react";
import closeIcon from "../../../../../assets/close.png";

const NoteViewTooltip = ({ data }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTitleClick = () => {
        setIsTooltipOpen(true);
    };

    const handleCloseClick = () => {
        setIsTooltipOpen(false);
    };

    const getFileNameFromUrl = (url) => {
        if (!url) return "No Attachment";
        return url.split('/').pop(); // Get the last part after the "/"
    };

    const formatDate = (value) => {
        if (value) {
            const date = new Date(value);

            // Format the date
            const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

            // Convert to the desired format
            return date.toLocaleString('en-us', options).replace(',', '');
        } else {
            return '-';
        }
    }

    return (
        <Tooltip
            title={
                <div className="tooltip-container">
                    <div className="close-button">
                        <img
                            src={closeIcon}
                            className="close-icon"
                            onClick={handleCloseClick}
                            alt="Close"
                        />
                    </div>

                    <div className="data-container">
                        <div className="notes-data-row">
                            <span className="data-label">Note</span>
                            <span className="title">{data.title}</span>
                        </div>

                        <div className="notes-data-row">
                            <span className="data-label">Note Details</span>
                            <span className="note-details">{data.noteDetails}</span>
                        </div>

                        <div className="notes-data-row">
                            <span className="data-label">Attachments</span>
                            {data.noteUrls.map((item, index) => (
                                <div className="attachment-details">
                                    <span>{getFileNameFromUrl(item)}</span>
                                    <span>{data.fileSizes[index]}</span>
                                </div>
                            ))}
                        </div>

                        {/* Created and Modified Details */}

                        <div className="dates-div">
                            <div className="notes-data-row">
                                <span className="data-label">Created Date:</span>
                                <span className="date">{formatDate(data.createdDate)}</span>
                            </div>
                            <div className="notes-data-row">
                                <span className="data-label">Last Modified:</span>
                                <span className="date">
                                    {formatDate(data.updatedAt)}
                                </span>
                                <span className="date">{data.updatedBy}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            color="white"
            overlayInnerStyle={{
                color: "#000",
                padding: "10px",
                borderRadius: "8px",
            }}
            placement="right"
            trigger="click" // Tooltip will appear only on click
            open={isTooltipOpen}
        >
            <div
                className="note-title"
                onClick={handleTitleClick}
            >
                <span>{data.title}</span>
            </div>
        </Tooltip>
    )

}

export default NoteViewTooltip;