import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logOutIcon from "../../assets/log-out.png";
import profileIcon from "../../assets/profile.png";
import "./Dashboard.css";
import {
  common_constants_name,
  common_constants_token,
} from "../../utils/commonConsts";
import { routes_login } from "../../utils/RoutingConts";

const Dashboard = () => {
  const navigate = useNavigate();
  const name = localStorage.getItem(common_constants_name);
  const Token = localStorage.getItem(common_constants_token);

  // Client-side example (React/JavaScript)
  useEffect(() => {
    if (!Token) {
      navigate(routes_login);
    }
  });

  const items = [
    {
      label: (
        <span className="drop-down-item">
          <img src={profileIcon} /> View Profile
        </span>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <span
          className="drop-down-item"
          onClick={() => {
            localStorage.clear();
            localStorage.removeItem(common_constants_token);
            navigate(routes_login);
          }}
        >
          <img src={logOutIcon} />
          Log out
        </span>
      ),
      key: "1",
    },
  ];

  return <div>dashboard</div>;
};

export default Dashboard;
