import React, { useEffect, useState } from "react";
import { Button, Drawer, Input, Row, Space, Spin, Table, message } from "antd";
import axios from "axios";
import edit_alt from "../../../assets/edit.png";
import delete_icon from "../../../assets/delete.png";
import { CloseOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../env";
import { _access_control_tab } from "../../../utils/commonConsts";

const Role = ({
  isDrawerOpen,
  setIsDrawerOpen,
  setActiveTab,
  setSelectedStaffRoleId,
  setSelectedStaffRoleName,
  setRoles,
}) => {
  const Token = localStorage.getItem("Token");
  const clinicId = localStorage.getItem("clinic_id");
  const [data, setData] = useState([]);
  const [currentRole, setCurrentRole] = useState(null); // To track the role being edited
  const [newRole, setNewRole] = useState("");

  const [spinner, setSpinner] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  // Fetch roles from the API
  const fetchRoles = () => {
    setSpinner(true);
    axios
      .get(`${API_BASE_URL}/api/v1/role/${clinicId}`, config)
      .then((response) => {
        console.log(response.data.data);
        const formattedData = response.data.data.map((item) => ({
          key: item.role_id,
          name: item.staff_role, // Map staff_role directly to name
          ...item,
        }));
        setData(formattedData);
        setSpinner(false);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        message.error("Failed to fetch roles.");
        setSpinner(false);
      });
  };

  // Open drawer for adding or editing a role
  const openDrawer = (role = null) => {
    setCurrentRole(role);
    setNewRole(role ? role.staff_role : ""); // Set initial value for edit
    setIsDrawerOpen(true);
  };

  // Handle adding or updating a role
  const handleSaveRole = async () => {
    setSpinner(true);
    if (!newRole.trim()) {
      message.error("Role name cannot be empty.");
      return;
    }

    if (currentRole) {
      // Update (PUT) role
      try {
        await axios.put(
          `${API_BASE_URL}/api/v1/role/${currentRole.role_id}/${currentRole.clinic_id}`,
          {
            staff_role: newRole,
            clinic_id: clinicId,
          },
          config
        );
        message.success("Role updated successfully.");
        setSpinner(false);
        setIsDrawerOpen(false);
        fetchRoles(); // Refresh the roles
      } catch (error) {
        console.error("Error updating role:", error);
        message.error("Failed to update role.");
        setSpinner(false);
      }
    } else {
      // Add (POST) role
      try {
        await axios.post(
          `${API_BASE_URL}/api/v1/role/createStaffRole`,
          {
            staff_role: newRole,
            clinic_id: clinicId,
          },
          config
        );
        message.success("Role added successfully.");
        setSpinner(false);
        setIsDrawerOpen(false);
        fetchRoles(); // Refresh the roles
      } catch (error) {
        console.error("Error adding role:", error);
        message.error("Failed to add role.");
        setSpinner(false);
      }
    }

    setNewRole("");
    setCurrentRole(null);
  };

  // Handle deleting a role
  const handleDeleteRole = async (record) => {
    setSpinner(true);
    try {
      await axios.delete(
        `${API_BASE_URL}/api/v1/role/deleteStaffRole/${record.role_id}/${record.clinic_id}`,
        config
      );
      message.success("Role deleted successfully.");
      setSpinner(false);
      fetchRoles(); // Refresh the roles
    } catch (error) {
      console.error("Error deleting role:", error);
      message.error("Failed to delete role.");
      setSpinner(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleButtonClick = (record) => {
    const formattedRoles = {
      value: record.key,
      label: record.name,
    };
    setRoles(formattedRoles);
    console.log(record);
    setSelectedStaffRoleId(record.key);
    setSelectedStaffRoleName(record.name);
    setActiveTab(_access_control_tab); // Set the active tab to 3
  };

  const columns = [
    {
      title: <span className="table-header-names">Name</span>,
      dataIndex: "name",
      key: "name",
      width: "95%",
      render: (text, record) => (
        <span
          className="role-name-text"
          onClick={() => {
            handleButtonClick(record);
          }}
        >
          {text}
        </span> // Apply custom CSS
      ),
    },
    {
      title: <span className="table-header-names">Action</span>,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <img
            src={edit_alt}
            alt="Edit"
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => openDrawer(record)}
          />
          <img
            src={delete_icon}
            alt="Delete"
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => handleDeleteRole(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="roles-main-div">
      <Spin spinning={spinner}>
        <Table columns={columns} dataSource={data} />
        <Drawer
          placement="right"
          onClose={() => setIsDrawerOpen(false)}
          open={isDrawerOpen}
          closeIcon={false}
          destroyOnClose
        >
          <Row className="d-flex jc-end">
            <CloseOutlined
              onClick={() => setIsDrawerOpen(false)}
              style={{ cursor: "pointer", fontSize: 24 }}
            />
          </Row>
          <Row>
            <span className="add-holiday-header" style={{ marginBottom: 20 }}>
              {currentRole ? "Edit Role" : "Add Role"}{" "}
            </span>
          </Row>
          <span style={{ padding: "20px 0 0 0" }} className="input-header">
            Name <span style={{ color: "#F00" }}>*</span>
          </span>
          <Row className="d-flex flex-col jc-between" style={{ height: "90%" }}>
            <Spin spinning={spinner}>
              <Input
                className="input"
                placeholder="Enter role name"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
              />
            </Spin>
            <Space className="w-100 d-flex jc-end">
              <Button onClick={() => setIsDrawerOpen(false)} disabled={spinner}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={handleSaveRole}
                disabled={spinner}
              >
                Save
              </Button>
            </Space>
          </Row>
        </Drawer>
      </Spin>
    </div>
  );
};

export default Role;
