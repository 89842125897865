import { Col, message, Row, Spin } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../../assets/arrow_back.png";
import editIcon from "../../../../assets/edit.png";
import API_BASE_URL from '../../../../env';
import { routes_patient_details } from '../../../../utils/RoutingConts';
import AppointmentOffcanvas from '../../../staff/staff-details/tabs/appointments/appointmentOffcanvas/AppointmentOffcanvas';
import './AppointmentDetails.css';

const AppointmentDetails = () => {
    const Token = localStorage.getItem("Token");
    const navigate = useNavigate();
    const { patientId, appointmentId } = useParams();
    const [appointment, setAppointment] = useState();
    const [staff, setStaff] = useState();
    const [patient, setPatient] = useState();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAppointmentData();
    }, [setAppointment])

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        },
        params: {
            appointmentSrNo: appointmentId
        }
    }

    const getAppointmentData = () => {
        setLoading(true);
        axios
            .get(`${API_BASE_URL}/api/v1/Appointments/get_appointments_by_appointmentId`, config)
            .then(res => {
                setAppointment(res.data.data);
                getStaffData(res.data.data.provider_id);
                getPatientById(res.data.data.patient_id);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data");
                setLoading(false);
            })
    }

    const getStaffData = (staffId) => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: staffId
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/providers/get-providers`, config)
            .then(res => {
                setStaff(res.data[0]);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data.")
            })
    }

    const getPatientById = (patientId) => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                patientId: patientId
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId`, config)
            .then(res => {
                const patient = [];
                patient.push(res.data);
                setPatient(patient);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the patients data");
            })
    }

    const appointmentDate = () => {
        const date = new Date(appointment?.date);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-UK', options);
    }

    const appointmentTime = (time, length) => {
        // Helper function to convert 24-hour time to 12-hour format
        const to12HourFormat = (hours, minutes) => {
            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
        };

        // Helper function to parse time (handles both 24-hour and 12-hour formats)
        const parseTime = (time) => {
            if (/AM|PM/i.test(time)) {
                const match = time.match(/^(\d+):(\d+)\s*(AM|PM)$/i);
                if (!match) throw new Error("Invalid time format");
                let [_, hours, minutes, period] = match; // Destructure match groups
                hours = Number(hours);
                minutes = Number(minutes);
                if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12; // Convert PM to 24-hour format
                if (period.toUpperCase() === 'AM' && hours === 12) hours = 0; // Handle midnight
                return [hours, minutes];
            } else if (/^\d{1,2}:\d{2}$/.test(time)) {
                return time.split(':').map(Number); // Already in 24-hour format
            } else {
                throw new Error("Invalid time format");
            }
        };

        try {
            // Parse the start time
            const [startHours, startMinutes] = parseTime(time);

            // Calculate the end time
            const totalMinutes = startHours * 60 + startMinutes + Number(length);
            const endHours = Math.floor(totalMinutes / 60) % 24; // Handle overflow past midnight
            const endMinutes = totalMinutes % 60;

            // Convert start and end times to 12-hour format
            const startFormatted = to12HourFormat(startHours, startMinutes);
            const endFormatted = to12HourFormat(endHours, endMinutes);

            return `${startFormatted} - ${endFormatted}`;
        } catch (error) {
            console.error("Error parsing time:", error.message);
            return "Invalid time";
        }
    };

    const handleEditAppointment = () => {
        setShow(true);
    }

    return (
        <div className="appointment-details-container">
            <Helmet>
                <title>Appointment Details</title>
            </Helmet>

            {loading && (
                <Spin className='spinner'></Spin>
            )}

            {!loading && appointment && staff && (
                <div className='appointment-details-view'>
                    <div className='appointment-details-header'>
                        <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(`${routes_patient_details}/${patientId}`)} />
                        <div className='appointment-info'>
                            <div className='appointment-basic-info'>
                                <Row>
                                    <span className='appointment-id'>{appointmentId}</span>
                                </Row>
                                <Row className='appointment-desc'>
                                    <span className='appointment-title'>{appointment.description}</span>
                                    -
                                    <span className='appointment-type'>{appointment.type}</span>
                                </Row>
                                <Row className='appointment-date-time'>
                                    <span>{appointmentDate()}</span>
                                    <span>{appointmentTime(appointment.time, appointment.length)}</span>
                                    <span>{`(${appointment.length} MIN)`}</span>
                                </Row>
                            </div>
                            <div className='appointment-personal-info'>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Doctor</span>
                                    <Row className='doctor-details'>
                                        <span className='doctor-name'>{`${staff.firstname} ${staff.lastname}`}</span>
                                        <span className='doctor-type'>{staff.specialty_code ? staff.specialty_code : 'N/A'}</span>
                                    </Row>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Clinic branch</span>
                                    <span className='address'>{`${staff.city} ${staff.state}, ${staff.zipcode}`}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Patient Id</span>
                                    <span className='patient-id'>{appointment.patient_id}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Patient name</span>
                                    <span className='patient-name'>{appointment.patient_name}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Age</span>
                                    {patient && (
                                        <span className='patient-name'>{patient[0]?.birthdate ? `${new Date().getFullYear() - new Date(patient[0]?.birthdate).getFullYear()} Years` : '-'}</span>
                                    )}
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Status</span>
                                    <span className='status'>Completed</span>
                                </Col>
                                <img src={editIcon} className='edit-icon' onClick={handleEditAppointment} />
                            </div>
                        </div>
                    </div>
                    <div className='appointment-details-body'>
                        <Row>
                            <span className='medical-record-text'>Medical Record</span>
                        </Row>
                        <Row className='appointment-details-row'>
                            <Col className='appointment-info-col'>
                                <label className='label'>history of dental problems</label>
                                <ul>
                                    <li> Sensitivity</li>
                                    <li> Cavities</li>
                                </ul>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>previous treatments</label>
                                <ul>
                                    <li> 2 Extractions - Wisdom, Premolar tooths</li>
                                    <li> 3 Cap Fillings - Wisdom, 2 Premolar tooths</li>
                                </ul>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>chronic illness</label>
                                <ul>
                                    <li>Diabetes</li>
                                    <li> Hypertension</li>
                                </ul>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>allergies</label>
                                <ul>
                                    <li>Anesthetics</li>
                                    <li> Wheezing</li>
                                </ul>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>additional note</label>
                                <span>Case of Othodontics, cavity level high</span>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>frequency of dental visits & exp with anesthesia</label>
                                <span>2 visits every 4 months. No major complain experienced with pre-anesthesia</span>
                            </Col>
                        </Row>
                        <Row className='appointment-details-row'>
                            <Col className='appointment-info-col'>
                                <label className='label'>DIAGNOSIS</label>
                                <span>
                                    Puss on upper gum area back-right. Clinical procedures required to remove unwanted foreign elements followed by right wisdom tooth extraction. No significant changes in medical  history since the last visit. Patient reports no allergies and no current medications
                                </span>
                            </Col>
                            <Col className='appointment-info-col'>
                                <label className='label'>current medications</label>
                                <span>
                                    HTN - Losacar 50 (Losartan) OD , Diabetes Type II -  Glycomet (Metformin) BDPC, Skin atrophy - Dermovate (Steroid Dermatits) SOS
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <AppointmentOffcanvas
                        show={show}
                        setShow={setShow}
                        mode={"edit"}
                        module={"patient"}
                        inputData={patient}
                        selectedAppointment={appointment}
                        getAppointments={getAppointmentData}
                    />
                </div>
            )}
        </div>
    )
}

export default AppointmentDetails;